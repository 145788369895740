import axios from 'axios';
import { IGuide } from '../../../../../types/entities/guide';
import { ERateValue } from '../../../../../types/entities/rate';
import { ISupplier } from '../../../../../types/entities/supplier';
import { captureException } from '@sentry/core';

export const guides: ISupplier[] = [
	{
		id: '1',
		title: 'Adventure guru',
		name: 'Jhon Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis id excepturi fuga architecto, sequi magnam ut iste voluptate recusandae adipisci tempora commodi hic totam impedit dignissimos nam assumenda eaque ipsam.',
		image: '/guide-1.png',
	},
	{
		id: '2',
		title: 'Adventure guru',
		name: 'Jhon Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis id excepturi fuga architecto, sequi magnam ut iste voluptate recusandae adipisci tempora commodi hic totam impedit dignissimos nam assumenda eaque ipsam.',
		image: '/guide-2.png',
	},
];

export const fetchGuides = async (): Promise<IGuide[]> => {
	try {
		const result: any = await new Promise(resolve => {
			setTimeout(function () {
				resolve({
					data: {
						collection: [
							{
								data: guides[0],
								tours: [
									{
										title: 'Lorem ipsum dolor sit amet.',
										rate: ERateValue.five,
										price: {
											currency: { name: 'RUB', symbol: '₽', prefix: false },
											value: 1999.99,
										},
										id: '1',
										image: '',
									},
									{
										title: 'Lorem ipsum dolor sit amet.',
										rate: ERateValue.four,
										price: {
											currency: { name: 'RUB', symbol: '₽', prefix: false },
											value: 1999.99,
										},
										id: '1',
										image: '',
									},
								],
							},
							{
								data: guides[1],
								tours: [
									{
										title: 'Lorem ipsum dolor sit amet.',
										rate: ERateValue.two,
										price: {
											currency: { name: 'RUB', symbol: '₽', prefix: false },
											value: 1999.99,
										},
										id: '1',
										image: '',
									},
									{
										title: 'Lorem ipsum dolor sit amet.',
										rate: ERateValue.four,
										price: {
											currency: { name: 'RUB', symbol: '₽', prefix: false },
											value: 1999.99,
										},
										id: '1',
										image: '',
									},
								],
							},
						],
					},
				});
			}, 0);
		});

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
		return result.data.collection as IGuide[];
	} catch (error) {
		captureException(error);

		return [];
	}
};
