import type { FC } from 'react';
import type { SelectProps as AntSelectProps } from 'antd/lib/select';

import AntSelect from 'antd/lib/select';
import { useMemo } from 'react';

export const Option = AntSelect.Option;
export const OptGroup = AntSelect.OptGroup;

export interface SelectProps extends AntSelectProps {
	searchKey?: string; // поле в массиве options, по нему будет искать фильтр при вкл `showSearch`
}

export const Select: FC<SelectProps> = props => {
	const { searchKey, ...p } = props;

	const { showSearch, filterOption } = useMemo(() => {
		if (!p.showSearch) {
			return {};
		}

		const customFilterOption = (inputValue: string, option?: { [key: string]: any }) => {
			const key = searchKey || 'label';

			const valueOption = String(option?.[key]).toLowerCase();
			const valueSearch = String(inputValue).toLowerCase();

			return valueOption.includes(valueSearch);
		};

		return {
			showSearch: p.showSearch,
			filterOption: customFilterOption,
		};
	}, [p.showSearch, searchKey]);

	return <AntSelect {...p} showSearch={showSearch} filterOption={filterOption} />;
};
