export const getStubByType = (locale: string, type: string): string => {
	switch (type) {
		case 'activity':
			return `/statics/new/detailGalleryStub/${locale}/activity.jpg`;
		case 'attraction':
			return `/statics/new/detailGalleryStub/${locale}/attraction.jpg`;
		case 'restaurant':
			return `/statics/new/detailGalleryStub/${locale}/restaurant.jpg`;
		case 'accommodation':
			return `/statics/new/detailGalleryStub/${locale}/accommodation.jpg`;
		case 'public_place':
			return `/statics/new/detailGalleryStub/${locale}/public_place.jpg`;
		default:
			return `/statics/new/detailGalleryStub/${locale}/restaurant.jpg`;
	}
};
