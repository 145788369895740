import axios from 'axios';
import { EActivityType, IActivityCard } from 'src/types/entities/activity';
import { captureException } from '@sentry/core';

const tours = [
	{
		id: 'd445c020-454e-11ed-a499-3899b28614ee',
		title: 'Pepe Nero',
		image: 'https://img.qvedo.com/images/741e56bb-f34b-1ba5-f719-5942a408ed94/Sx3',
		type: EActivityType.restaurant,
		slug: 'd445c020-454e-11ed-a499-3899b28614ee',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
	{
		id: 'e1294fa0-454e-11ed-aa0c-641eb050f7b4',
		title: 'Eat & Talk',
		image: 'https://img.qvedo.com/images/8c484d66-8ed6-88a4-f33a-d792e1818463/Sx3',
		type: EActivityType.restaurant,
		slug: 'e1294fa0-454e-11ed-aa0c-641eb050f7b4',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
	{
		id: '4df01e50-4547-11ed-89f2-d330597da60b',
		title: 'Старая Башня',
		image: 'https://img.qvedo.com/images/f7ef529f-cc3c-f888-aab0-ef1997a7dd04/Sx3',
		type: EActivityType.restaurant,
		slug: '4df01e50-4547-11ed-89f2-d330597da60b',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
	{
		id: '32be8530-44cb-11ed-8fa6-54c5a3f10306',
		title: 'Старик и море',
		image: 'https://img.qvedo.com/images/3a389e90-7028-e7ee-2231-163701b148f5/Sx3',
		type: EActivityType.restaurant,
		slug: '32be8530-44cb-11ed-8fa6-54c5a3f10306',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
	{
		id: '1e88d5b0-44ef-11ed-bef7-1001e572651f',
		title: 'Kozlovna',
		image: 'https://img.qvedo.com/images/0ac6c08d-0b04-9864-9e53-cda64d67091c/Sx3',
		type: EActivityType.restaurant,
		slug: '1e88d5b0-44ef-11ed-bef7-1001e572651f',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
	{
		id: '3ec0d210-44ef-11ed-a53a-4edb915d08dd',
		title: 'Летс Рок Бар',
		image: 'https://img.qvedo.com/images/5213a153-f198-2ecf-0808-e61ad0c79cc2/Sx3',
		type: EActivityType.restaurant,
		slug: '3ec0d210-44ef-11ed-a53a-4edb915d08dd',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
];

export const fetchPopularTours = async (count: number): Promise<IActivityCard[]> => {
	try {
		const result: any = await new Promise(resolve => {
			setTimeout(function () {
				resolve({
					data: {
						tours,
					},
				});
			}, 0);
		});

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
		return result.data.tours.slice(0, count) as IActivityCard[];
	} catch (error) {
		captureException(error);

		return [];
	}
};
