import { getBrowserHttpClient } from '@app/api/browserHttpApi';

import { currencyRatesMapper } from './mapper';
import { ICurrencyRatesResponse } from './types';
import { ICurrencyRates } from '@main/src/types/common/currencyRates';
import { captureException } from '@sentry/core';
import dayjs, { Dayjs } from 'dayjs';

export interface IStorageCurrencyRates {
	time: Dayjs | null;
	rates: ICurrencyRates | null;
}

const LOCALE_STORAGE_KEY = 'qvd/cur-rates';
const CACHE_TIME_MINUTES = 120;

function readCacheLocalStorage(): IStorageCurrencyRates | null {
	const cacheData = global.localStorage.getItem(LOCALE_STORAGE_KEY);
	if (!cacheData) {
		return null;
	}

	try {
		return JSON.parse(cacheData) as IStorageCurrencyRates;
	} catch (error) {
		return null;
	}
}
export const fetchCurrencyRates = async (disable?: boolean): Promise<ICurrencyRates | null> => {
	if (disable) {
		return null;
	}

	const cachedData = readCacheLocalStorage();

	const currentDate = dayjs();
	if (
		cachedData &&
		cachedData.time !== null &&
		cachedData.rates !== null &&
		currentDate.diff(cachedData.time, 'minutes') < CACHE_TIME_MINUTES
	) {
		return cachedData.rates;
	}

	const api = await getBrowserHttpClient();

	try {
		const result = await api.post<{ data: ICurrencyRatesResponse }>(
			'/client/order/currency-rates',
			{},
			{
				timeout: 2000,
			}
		);

		const mappedData = currencyRatesMapper(result.data);
		global.localStorage.setItem(
			LOCALE_STORAGE_KEY,
			JSON.stringify({
				time: currentDate,
				rates: mappedData,
			})
		);

		return mappedData;
	} catch (error) {
		captureException(error);

		return null;
	}
};
