import { ICurrencyRatesResponse } from '@app/api/services/node/currencyRates/types';
import { ICurrencyRate, ICurrencyRates } from '@main/src/types/common/currencyRates';
import { reduce } from 'lodash';

export const currencyRatesMapper = (rates: ICurrencyRatesResponse): ICurrencyRates => {
	return reduce(
		rates,
		(result, value, key) => {
			result[key] = value as ICurrencyRate;
			return result;
		},
		{} as ICurrencyRates
	);
};
