import { DEFAULT_LOCALE } from '@app/constants/defaults';
import { ILocale } from '@main/src/types/common';
import { locales } from '@app/constants/locales';

export const getLocalCookie = (lang: string | undefined, cookie: string | null | undefined) => {
	const locale = lang || DEFAULT_LOCALE;
	let result: ILocale | null = null;
	if (typeof cookie === 'undefined' || cookie === null) {
		result = locales.find(f => f.code === locale) || null;
	} else {
		result = JSON.parse(cookie) as ILocale;
	}

	return result;
};
