import { EActivityType, IActivityCard } from '../../../../../types/entities/activity';
import { TTagKey } from '../../../../../types/search';
import take from 'lodash/fp/take';
import shuffle from 'lodash/fp/shuffle';

const popular: IActivityCard[] = [
	{
		id: '1',
		title: 'Популярный тур №1',
		image: '/tour-3.png',
		images: [],
		type: EActivityType.activity,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 20999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '2',
		title: 'Популярный тур №2',
		image: '/tour-3.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 20999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '3',
		title: 'Популярный тур №3',
		image: '/tour-3.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 20999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
];

const newOffers: IActivityCard[] = [
	{
		id: '7',
		title: 'Новый тур №1',
		image: '/tour-4.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 49999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '8',
		title: 'Новый тур №2',
		image: '/tour-4.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 49999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '9',
		title: 'Новый тур №3',
		image: '/tour-4.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 49999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '10',
		title: 'Новый тур №4',
		image: '/tour-4.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 49999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
];

const cheap: IActivityCard[] = [
	{
		id: '13',
		title: 'Дешевый тур №1',
		image: '/tour-5.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 9999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '14',
		title: 'Дешевый тур №2',
		image: '/tour-5.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 9999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '15',
		title: 'Дешевый тур №3',
		image: '/tour-5.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 9999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
];

const top: IActivityCard[] = [
	{
		id: '19',
		title: 'ТОП тур №1',
		image: '/tour-6.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 129999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '20',
		title: 'ТОП тур №2',
		image: '/tour-6.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 129999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '21',
		title: 'ТОП тур №3',
		image: '/tour-6.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 129999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
];

const premium: IActivityCard[] = [
	{
		id: '25',
		title: 'Премимум тур №1',
		image: '/tour-7.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 199999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '26',
		title: 'Премимум тур №2',
		image: '/tour-7.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 199999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
];

const luxury: IActivityCard[] = [
	{
		id: '31',
		title: 'Luxury тур №1',
		image: '/tour-8.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 299999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
	{
		id: '32',
		title: 'Luxury тур №2',
		image: '/tour-8.png',
		images: [],
		type: EActivityType.attraction,
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false, code: 'ru' },
			value: 299999.99,
		},
		rating: null,
		priceLevel: null,
		openNow: false,
		closed: null,
		description: '',
		city: '',
		hotelRates: null,
	},
];
export const mockByTags: { [key in TTagKey]: IActivityCard[] } = {
	popular,
	new: newOffers,
	cheap,
	top,
	premium,
	luxury,
};

// TODO remove after api sync
export interface ISearchFilters {
	tags: TTagKey[];
}

// TODO remove after api sync
export const makeFilteredMocks = ({ tags }: ISearchFilters): IActivityCard[] => {
	if (tags.length === 1) {
		return mockByTags[tags[0]];
	} else if (tags.length > 1) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
		return take(6, shuffle(tags.map(tag => mockByTags[tag]).flat()));
	}

	return [
		mockByTags['popular'][0],
		mockByTags['new'][0],
		mockByTags['cheap'][0],
		mockByTags['top'][0],
		mockByTags['premium'][0],
		mockByTags['luxury'][0],
	];
};
