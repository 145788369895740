import { IMapperParams, NearbyCardsNode } from '@app/api/services/node/supplier/types';
import { ISupplierDetail, NearbyCardsView } from '@main/src/types/supplier';
import { IMAGE_SIZE } from '@app/api/services/shared/serp/mapper';
import { getCurrency } from '@common/helpers';
import { ICurrencyRates } from '@main/src/types/common/currencyRates';
import { ICurrency } from '@main/src/types/common';

export const supplierPageMapper = ({ result }: IMapperParams): ISupplierDetail | null => {
	const { data } = result;

	if (data) {
		return {
			id: data?.id || '',
			name: data?.displayName || '',
			description: data?.description || '',
			image: (data?.cover && `https://img.qvedo.com/avatars/${data.cover}/Sx3`) || '',
		};
	}

	return null;
};

export const supplierCardMapper = (
	data: NearbyCardsNode,
	currency?: ICurrency | null,
	currencyRates?: ICurrencyRates | null
): NearbyCardsView => {
	const cards =
		data.cards?.map(({ card, geo }) => {
			const images = card.images.map(image => {
				if (image.startsWith('https')) {
					return image.replace('{size}', IMAGE_SIZE);
				}

				return `https://img.qvedo.com/images/${image}/Mx2`;
			});
			const [firstImage, ...restImages] = images;

			let currentCurrency = null;
			let currencyPrice = null;
			const currencies = Object.keys(card.price || {})[0];
			if (currencies && currency?.code && card.price) {
				if (currencyRates) {
					currencyPrice = card.price && (card.price[currencies] * currencyRates[currencies][currency?.code]) / 100;
					currentCurrency = currency;
				} else {
					currencyPrice = card.price[currencies] / 100;
					currentCurrency = getCurrency(currencies);
				}
			}

			return {
				id: card.card_id,
				title: card.title.req,
				price: {
					value: currencyPrice,
					currency: currentCurrency,
				},
				type: 'activity',
				image: firstImage || null,
				priceLevel: null,
				rating: null,
				openNow: true,
				closed: null,
				description: card.texts.req.short,
				city: geo?.city_name || null,
				images: restImages || [],
				distance: -1,
			};
		}) || null;

	const { cards_cnt } = data;

	return <NearbyCardsView>{ cards, cards_cnt };
};
