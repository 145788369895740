import COUNTRY_CODES from '../taxonomy/cmn/country.json';

export const OFFICE_CODES = ['ru', 'si', 'th'] as const;
export const REGION_CODES = [
  'africa',
  'arab_states',
  'asia_pacific',
  'europe',
  'middle_east',
  'america',
  'cis_asia',
] as const;

export type OfficeCode = (typeof OFFICE_CODES)[number];
export type RegionCode = (typeof REGION_CODES)[number];

export type CmnCountry = {
  region: RegionCode;
  priority: number;
  is_eu: boolean;
  currency: MapPriceCurrency;
  lang: LangCodeForUI;
  office?: OfficeCode;
  map?: {
    lat: number;
    lng: number;
  };
};

function isKnownCountryCode(cc: string): cc is keyof typeof COUNTRY_CODES {
  return cc in COUNTRY_CODES;
}

export function getCountry(cc: string): CmnCountry | undefined {
  if (isKnownCountryCode(cc)) {
    return COUNTRY_CODES[cc] as CmnCountry;
  }
}
