import axios from 'axios';

import { PageResponse } from '@app/api';
import { IAboutB2CProps, IAboutPageProps } from '@main/src/types/pages/about';
import { DATA } from './constants';
import { captureException } from '@sentry/core';

export const fetchAboutB2BPage = async (lang: string): Promise<IAboutPageProps | null> => {
	try {
		// const result = await nodeHttpApi.apiget<{ data: { page: IStrapiPage & { data: IAboutPageProps } } }>('/pages/about', {
		// 	lang,
		// });

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (!DATA[lang]) {
			return null;
		}

		const result: { data: { page: PageResponse & { data: IAboutB2CProps } } } = await new Promise(resolve => {
			setTimeout(function () {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				resolve(DATA[lang] as { data: { page: PageResponse & { data: IAboutB2CProps } } });
			}, 0);
		});

		return result.data.page.data;
	} catch (error) {
		captureException(error);

		return null;
	}
};
