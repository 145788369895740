import { ITag } from '@main/src/types/activity';

export const getAccommodationKd = (tags: ITag[] | undefined): string => {
	if (tags) {
		const result = tags.find(({ name }) => name.includes('accommodation.kd'));
		if (result) {
			return result.name;
		}
	}
	return '';
};
