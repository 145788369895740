import { IRequestParams, IResponseData } from './types';
import { captureException } from '@sentry/core';
import { ICardResultV2 } from '@main/src/types/map/searchArea';
import { mapperCardsShort } from '@app/api/services/shared/cardsShort/mapper';
import { ICurrencyRates } from '@main/src/types/common/currencyRates';
import { ICurrency } from '@main/src/types/common';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';

export const fetchCardsShort = async ({
	cardIds,
	locale,
	currencyRates,
	currency,
}: {
	locale: string;
	cardIds: string[];
	currencyRates?: ICurrencyRates | null;
	currency?: ICurrency | null;
}): Promise<null | ICardResultV2[]> => {
	const api = await getBrowserHttpClient();
	try {
		if (cardIds.length === 0) {
			return [];
		}

		const result = await api.post<IResponseData, IRequestParams>('/map2/cards-short', {
			lang: locale,
			card_ids: cardIds,
		});

		return mapperCardsShort(result, currencyRates, currency);
	} catch (error) {
		captureException(error);

		return null;
	}
};
