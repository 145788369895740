import { useState } from 'react';
import { InputNumber } from './InputNumber';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import type { InputNumberProps } from 'antd/lib/input-number';
import { Button } from '@new/components/ui/Button';

const buttonStyles = { paddingLeft: '6px', paddingRight: '6px' };
const iconStyles = { fontSize: '18px', color: '#BEC0C5' };

export const Counter = ({ min, max, defaultValue, ...props }: InputNumberProps) => {
	const [value, setValue] = useState<number>((defaultValue as number) ?? 0);

	return (
		<div>
			<Button
				onClick={() => {
					if (value > (min as number)) {
						setValue(prev => prev - 1);
					}
				}}
				style={buttonStyles}
			>
				<MinusOutlined style={iconStyles} />
			</Button>

			<InputNumber min={min} max={max} controls={false} {...props} value={value} />

			<Button
				onClick={() => {
					if (value < (max as number)) {
						setValue(prev => prev + 1);
					}
				}}
				style={buttonStyles}
			>
				<PlusOutlined style={iconStyles} />
			</Button>
		</div>
	);
};
