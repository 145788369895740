import axios from 'axios';
import { currenciesMapper } from '@app/api/services/node/currencies/mapper';
import { ICurrency } from 'src/types/common';
import { currencies } from '@app/constants/currencies';
import { captureException } from '@sentry/core';
import { getCountry } from '@main/shared/utils/country_utils';
import { DEFAULT_CURRENCY } from '@app/constants/defaults';

export const fetchCurrencies = async (): Promise<ICurrency[]> => {
	try {
		// const result = await nodeHttpApi.apiget<{ data: IndexCurrenciesResponse }>('/currencies');

		return new Promise(resolve => resolve(currencies));
		// return currenciesMapper(result.data.currencies);
	} catch (error) {
		captureException(error);

		return []; // TODO default currency
	}
};

interface FetchOneCurrencyParams {
	code?: string;
	cc?: string | null;
}

export const fetchOneCurrency = ({ code = DEFAULT_CURRENCY, cc }: FetchOneCurrencyParams): ICurrency | null => {
	try {
		// const result = await nodeHttpApi.apiget<{ data: ShowCurrencyResponse }>(`/currencies/${code}`);
		if (cc) {
			const country = getCountry(cc);
			code = country?.currency || code;
		}

		return currencies.find(f => f.code === code) || null;

		// return result.data;
	} catch (error) {
		captureException(error);

		return null;
	}
};
