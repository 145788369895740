import type { TypographyProps } from 'antd/lib/typography';

import AntTypography from 'antd/lib/typography';

export const Typography = (props: TypographyProps) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return <AntTypography {...props} />;
};

Typography.Title = AntTypography.Title;
Typography.Paragraph = AntTypography.Paragraph;
