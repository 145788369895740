import { ICurrency } from '@main/src/types/common';
import { ICurrencyRates } from '@main/src/types/common/currencyRates';
import { IFetchOrderResponse } from '@app/api/services/shared/order/types';

export enum EOrderStatus {
	cancel_s = 'cancel_s',
	cancel_u = 'cancel_u',
	created = 'created',
	payment = 'payment',
	wait_pay = 'wait_pay',
	expired = 'expired',
	processing = 'processing',
	failed = 'failed',
}

export interface IOrderResponse {
	order_id: string;
	order_number: string;
	org_id: string;
	usr_id: string;
	order_details: {
		card_id: number;
		org_id: number;
		order_sum: number;
		currency: string;
		lang: string;
		offer: {
			offer_id: number;
			offer_title: string;
			offer_image?: null | string;
		};
		tickets_date: number;
		tickets: [
			{
				ticket_id: string;
				ticket_title: string;
				ticket_price: number;
				ticket_number: number;
			}
		];
		email?: null | string;
		/** признак автоматического подтверждения заказа */
		auto_confirm?: true;
		/** ID внешней системы для заказа */
		ext_src?: OrderExtSrc;
	};
	order_ts: number;
	order_dialog: string;
	claim_dialog?: null | string;
	order_status: EOrderStatus;
	cancel_reason?: null | string;
	payment_before?: null | number;
}

export interface IFetchOrdersServiceParams {
	currency?: ICurrency | null;
	currencyRates?: ICurrencyRates | null;
}

export interface IMapperParams {
	orders: IOrderResponse[];
	currency?: ICurrency | null;
	currencyRates?: ICurrencyRates | null;
}
