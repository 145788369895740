import { CARD_TYPE } from '@main/shared/filters';
import { ICountry } from '@main/src/types/common';
import { AutocompleteCard, AutocompletePlace, IGeoSearchEntry, TGeoSearchGroupType } from './type';
import { matchSorter } from 'match-sorter';
import { BBox } from '@turf/helpers';
import { bboxByCoordinates } from '@common/helpers/geo/bbox';
import { convertGlobMapCardTypeToType } from '@app/api/services/node/cardInfo/mapper';
import { TGlobMapCardType } from '@app/api/services/node/nextStartHome/types';

export const getCityList = (groupType: TGeoSearchGroupType, cities: IGeoSearchEntry[]): ICountry[] =>
	cities.map(city => {
		const { city_id, bbox, dist_meters, name_native, name_req, cc, location, address } = city;

		return {
			code: '',
			center: [location[1], location[0]],
			name: name_req,
			groupType,
			cityId: city_id,
			bbox,
			address: address?.display.reverse().join(', ') || name_req,
		};
	});

const sortedTypeMapper: TGeoSearchGroupType[] = ['capital', 'state_capital', 'city', 'town', 'village'];

export const citySearchMapper = (result: SerpComboSearchResp): ICountry[] => {
	const geo = result?.geo || {};
	const prepared = sortedTypeMapper.reduce((acc, key) => {
		// return [];
		return [...acc, ...getCityList(key, geo[key] || [])];
	}, [] as ICountry[]);

	return prepared;
};

const PRIORITY: Record<GeoSearchGroupType | GeoEtgSearchGroupType, number> = {
	capital: 1,

	city: 2,
	state_capital: 2,

	multi: 3,
	state: 3,

	town: 4,
	poi: 4,
	air: 4,
	rail: 4,
	sub: 4,

	village: 5,
};

const COUNTRY_WEIGHT: Record<string, number> = {
	ru: 50, // Россия
	by: 50, // Беларусь
	kz: 40, // Казахстан

	th: 50, // Таиланд

	vn: 20, // Вьетнам
	ae: 20, // ОАЭ
	in: 10, // Индия
	tr: 20, // Турция

	si: 30, // Словения
	cz: 10, // Чехия
	pl: 10, // Польша
	at: 10, // Австрия
	it: 30, // Италия
	hr: 10, // Хорватия
	de: 10, // Германия
	gr: 10, // Греция
	dk: 10, // Дания
	ie: 10, // Ирландия
	es: 10, // Испания
	cy: 10, // Кипр
	nl: 10, // Нидерланды
	pt: 10, // Португалия
	fi: 5, // Финляндия
	fr: 5, // Франция
	se: 5, // Швеция
	bg: 5, // Болгария
	hu: 5, // Венгрия
	lv: 0, // Латвия
	lt: 0, // Литва
	lu: 5, // Люксембург
	mt: 5, // Мальта
	ro: 5, // Румыния
	sk: 5, // Словакия
	be: 5, // Бельгия
	ee: 0, // Эстония
};

function measureWeight(place: AutocompletePlace, initialWeight: number) {
	let weight = initialWeight;

	if (place.type === 'capital') {
		weight += 500;
	}

	if (place.type === 'city' || place.type === 'state_capital') {
		weight += 80;
	}

	if (place.type === 'poi') {
		weight += 70;
	}

	if (place.type === 'multi' || place.type === 'state') {
		weight += 60;
	}

	if (place.type === 'town') {
		weight += 60;
	}

	if (place.type === 'air') {
		weight += 10;
	}

	if (place.type === 'village') {
		weight -= 10;
	}

	if (place.cc && COUNTRY_WEIGHT[place.cc]) {
		weight += COUNTRY_WEIGHT[place.cc];
	}

	const distanceFix = Math.round(place.distance / 100000);
	weight -= distanceFix;

	return weight;
}

const RADIUS: Record<string, number> = {
	air: 10,
	state: 45,
	multi: 45,
	poi: 25,
};

export function prepareGeoSearchResults(result: SerpComboSearchResp, needle: string) {
	// let places: AutocompletePlace[] = [];

	const unsortedPlaces = Object.entries({
		...result.geo,
		...result.geo_etg,
	})
		.map(([type, obj]) => {
			// TODO fix types bbox not used in app
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return obj.map<AutocompletePlace>(o => {
				const address = 'address' in o && o.address ? o.address.display.slice(1).join(', ') : undefined;

				let bbox: BBox;

				if ('bbox' in o) {
					const points = o.bbox.split(',');
					bbox = [+points[1], +points[0], +points[3], +points[2]];
				} else {
					const customBB = bboxByCoordinates(
						{
							latitude: o.location[0],
							longitude: o.location[1],
						},
						RADIUS[type]
					);

					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					bbox = customBB.bbox;
				}

				const [lng1, lat1, lng2, lat2] = bbox;

				return {
					type,
					id: 'city_id' in o ? o.city_id : o.location.join('_'),
					title: o.name_req,
					bbox: [
						[lng1, lat1],
						[lng2, lat2],
					],
					location: o.location,
					cc: o.cc,
					distance: o.dist_meters,
					address,
					priority: PRIORITY[type as GeoSearchGroupType],
					weight: 0,
				};
			});
		})
		.flat()
		.map((p, index) => {
			return {
				...p,
				weight: measureWeight(p, index),
			};
		})
		.sort((a, b) => a.distance - b.distance)
		.sort((a, b) => b.weight - a.weight);

	// places = matchSorter(unsortedPlaces, needle, {
	// 	keys: ['title', 'location'],
	// })
	// 	.map((p, index) => {
	// 		return {
	// 			...p,
	// 			weight: measureWeight(p, index),
	// 		};
	// 	})
	// 	.sort((a, b) => b.weight - a.weight);

	const cards: AutocompleteCard[] = Object.entries(result.card)
		.map(([type, obj]) => {
			return obj.map<AutocompleteCard>(o => {
				return {
					type: convertGlobMapCardTypeToType(type as TGlobMapCardType),
					id: o.card_id,
					title: o.name_req,
					location: o.location,
					cc: o.cc,
					distance: o.dist_meters,
					priority: CARD_TYPE[type].priority,
				};
			});
		})
		.flat()
		.sort((a, b) => a.priority - b.priority || a.distance - b.distance)
		.slice(0, 20);

	return {
		places: unsortedPlaces,
		cards,
	};
}
