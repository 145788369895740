import { currencies, USD_CURRENCY } from '@app/constants/currencies';
import { ICurrency } from '@main/src/types/common';

export const getCurrency = (code: string | null): ICurrency => {
	if (code) {
		const currency = currencies.find(i => i.code === code);

		if (currency) {
			return currency;
		}
	}

	return USD_CURRENCY;
};
