import { fetchOrders, IChatLastMessageParams, IChatNewDialogParams, IChatPostMessageReq } from '@app/api';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { IDialogUser, TChatMessage } from '@main/src/types/profile/messages';
import DOMPurify from 'dompurify';
import { fetchPublicProfile } from '../../user';
import { dialogsMapper, messagesMapper } from './mapper';

import {
	TChatDialogMsg,
	IChatLastMessageReq,
	IChatNewCounterResp,
	IChatNewDialogReq,
	IChatPostMessageParams,
	IFetchListDialogs,
	IFetchListOrgDialogs,
	IFetchOrgListDialogs,
	TChatDialogInfo,
	TChatListDialogsResp,
} from './types';
import { captureException } from '@sentry/core';

export const fetchListDialogs = async ({
	lang,

	dialog_type,
	currencyRates,
	currency,
}: IFetchListDialogs): Promise<IDialogUser[] | null> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<TChatListDialogsResp>(
			'/client/chat/list_dialogs',
			{
				lang,
				dialog_type,
			},
			{
				headers: {},
			}
		);
		const orders = await fetchOrders({ currencyRates, currency });

		const user_ids: Set<string> = new Set();
		const profiles = [];

		for (let index = 0; index < result.data.dialogs.length; index++) {
			// TODO
			// Делаем проверку. Если в массив id user собралось 9 айдишников, то делаем запрос
			// Так как на данный момент даная ручка может принимать максимум 9 айдишников.
			user_ids.add(result.data.dialogs[index].usr_two);
			if (
				[...user_ids].length === 9 ||
				([...user_ids].length > 0 && typeof result.data.dialogs[index + 1] === 'undefined')
			) {
				const profile = await fetchPublicProfile({ user_ids: [...user_ids] });
				profiles.push(profile);
				user_ids.clear();
			}
		}
		return dialogsMapper(result.data.dialogs, profiles.flat(), orders);
	} catch (error) {
		captureException(error);

		return null;
	}
};

export interface IFetchSendMessage {
	dialog: string;
	lang: string;
	text: string;
}
export const fetchSendMessage = async ({ lang, text, dialog }: IFetchSendMessage): Promise<null> => {
	const api = await getBrowserHttpClient();
	try {
		await api.post<TChatListDialogsResp>('/client/chat/new_message', {
			lang,
			text,
			dialog,
		});

		return null;
	} catch (error) {
		captureException(error);

		return null;
	}
};

export const fetchCountNew = async (): Promise<number | null> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.get<IChatNewCounterResp>('/client/chat/count_new');

		return result.data.new_count;
	} catch (error) {
		captureException(error);

		return null;
	}
};

interface IFetchChatReadMessageParams {
	dialog: string;
	after?: number; // с меткой времени больше указанной. по умолчанию - 0
	lang: string;
}

interface IFetchChatReadMessageRequest {
	dialog: string;
	after?: number; // с меткой времени больше указанной. по умолчанию - 0
	lang: string;
}
export const fetchChatReadMessage = async ({
	dialog,
	after,
	lang,
}: IFetchChatReadMessageParams): Promise<TChatMessage[]> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<{ data: TChatDialogMsg[] }, IFetchChatReadMessageRequest>(
			'/client/chat/messages',
			{
				dialog,
				after,
				lang,
			},
			{
				headers: {},
			}
		);

		return messagesMapper(result.data);
	} catch (error) {
		captureException(error);

		return [];
	}
};

export interface IFetchMarkReadMessage {
	dialog: string;
	up_to: number; // с меткой времени до указанной
}

export const fetchMarkReadMessage = async ({ dialog, up_to }: IFetchMarkReadMessage): Promise<null> => {
	const api = await getBrowserHttpClient();
	try {
		await api.post<{ data: TChatDialogMsg[] }>(
			'/client/chat/mark_read',
			{
				dialog,
				up_to,
			},
			{
				headers: {},
			}
		);

		return null;
	} catch (error) {
		captureException(error);

		return null;
	}
};

export const fetchNewMessage = async ({
	lang,
	dialog,
	text,
	msg_data,
}: IChatPostMessageParams): Promise<void | null> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<{ data: void }, IChatPostMessageReq>(
			'/client/chat/new_message',
			{
				lang,
				dialog,
				msg_data,
				text: DOMPurify.sanitize(text),
			},
			{
				headers: {},
			}
		);

		return result.data;
	} catch (error) {
		captureException(error);

		return null;
	}
};
export const fetchNewDialog = async ({
	dialog_type,
	dialog_subj,
	dialog_obj,
	lang,
	org_two,
}: IChatNewDialogParams): Promise<string | null> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<{ data: { dialog: string } }, IChatNewDialogReq>(
			'/client/chat/new_dialog',
			{
				lang,
				dialog_type,
				dialog_subj,
				dialog_obj,
				org_two,
			},
			{
				headers: {},
			}
		);

		return result.data.dialog;
	} catch (error) {
		captureException(error);

		return null;
	}
};

export const fetchChatsLatMessages = async ({
	lang,
	dialogs,
}: IChatLastMessageParams): Promise<Record<string, TChatMessage> | null> => {
	const api = await getBrowserHttpClient();
	try {
		const { data } = await api.post<{ data: Record<string, TChatDialogMsg> }, IChatLastMessageReq>(
			'/client/chat/last_messages',
			{
				lang,
				dialogs,
			},
			{
				headers: {},
			}
		);

		const messages = messagesMapper(Object.values(data));
		const newObjectLastMessage: Record<string, TChatMessage> = {};
		Object.keys(data).map((key, index) => {
			newObjectLastMessage[key] = messages[index];
		});

		return newObjectLastMessage;
	} catch (error) {
		captureException(error);

		return null;
	}
};
