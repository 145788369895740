import axios from 'axios';
import { EActivityType, IActivityCard } from 'src/types/entities/activity';
import { captureException } from '@sentry/core';

const attractive = [
	{
		id: '09820870-44f0-11ed-a44e-e1f5e8585e2a',
		title: 'Мзиури',
		image: 'https://img.qvedo.com/images/6b7e9146-ea6b-5555-32ed-7bc7cfb3b32e/Sx3',
		type: EActivityType.restaurant,
		slug: '09820870-44f0-11ed-a44e-e1f5e8585e2a',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
	{
		id: 'c96dbf70-454b-11ed-a1b3-57df6aa971fb',
		title: 'Il Forno',
		image: 'https://img.qvedo.com/images/9603a09b-4309-33b2-3742-11617e821313/Sx3',
		type: EActivityType.restaurant,
		slug: 'c96dbf70-454b-11ed-a1b3-57df6aa971fb',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
	{
		id: '9a3dab70-454b-11ed-9162-eb11fca75e3b',
		title: 'Vаниль',
		image: 'https://img.qvedo.com/images/d4c142c7-6929-2494-a090-917381e52d02/Sx3',
		type: EActivityType.restaurant,
		slug: '9a3dab70-454b-11ed-9162-eb11fca75e3b',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
	{
		id: '60407e20-4546-11ed-a2eb-0ec05e113859',
		title: 'Medusa',
		image: 'https://img.qvedo.com/images/b5b43263-875f-2d3c-ac43-91a535abecbd/Sx3',
		type: EActivityType.restaurant,
		slug: '60407e20-4546-11ed-a2eb-0ec05e113859',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
	{
		id: '6dd84d00-4547-11ed-ba19-e947682722b7',
		title: 'Coffee Inn',
		image: 'https://img.qvedo.com/images/d7d20d4a-788d-1988-7ad4-1a3dd32152de/Sx3',
		type: EActivityType.restaurant,
		slug: '6dd84d00-4547-11ed-ba19-e947682722b7',
		price: {
			currency: { name: 'RUB', symbol: '₽', prefix: false },
			value: 4999.99,
		},
	},
];

export const fetchAttractiveTours = async (): Promise<IActivityCard[]> => {
	try {
		const result: any = await new Promise(resolve => {
			setTimeout(function () {
				resolve({
					data: {
						attractive,
					},
				});
			}, 0);
		});

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
		return result.data.attractive as IActivityCard[];
	} catch (error) {
		captureException(error);

		return [];
	}
};
