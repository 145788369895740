import { userMapper } from './mapper';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';

import type { IUpdateUserRequest, IUserProfile, IUserResponse } from '@main/src/types/apiCustom';
import type { IUser } from '@main/src/types/common';
import dayjs from 'dayjs';
import { captureException } from '@sentry/core';
import { fetchNotificationReadEmail, fetchNotificationSaveEmail, fetchNotificationsSave } from '../profile';

interface IResponse {
	data: {
		user: IUserResponse;
	};
}

export const patchUser = async (data: IUser): Promise<IUser | undefined> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<IResponse, { data: { user: { profile: IUpdateUserRequest } } }>(
			'/client/user/update_profile',
			{
				data: {
					user: {
						profile: {
							gender: data.gender,
							last_name: data.lastName,
							first_name: data.firstName,
							avatar: data.avatar,
							birth_date: data.birthDate ? dayjs(data.birthDate).format('YYYY-MM-DD') : '',
							email: data.email,
							phone_number: data.phoneNumber,
							citizenship: data.citizenship,
						},
					},
				},
			},
			{
				headers: {},
			}
		);

		return userMapper(result.data.user);
	} catch (error) {
		captureException(error);

		return undefined;
	}
};
export const fetchUser = async (): Promise<IUser | undefined> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<IResponse>(
			'/client/user/show_profile',
			{},
			{
				headers: {},
			}
		);
		const preparedResult = userMapper(result.data.user);
		const emails = await fetchNotificationReadEmail();
		if (!emails?.com && result.data.user.profile.email) {
			await fetchNotificationsSave({ profile: 'chat', email: 'com' });
			await fetchNotificationsSave({ profile: 'order', email: 'com' });
			await fetchNotificationsSave({ profile: 'market', email: 'com' });
			await fetchNotificationSaveEmail({
				email: result.data.user.profile.email,
				email_type: 'com',
			});
		}

		return {
			...preparedResult,
			email: emails?.com || '',
		};
	} catch (error) {
		captureException(error);

		return undefined;
	}
};

export const deleteUser = async (): Promise<boolean | undefined> => {
	const api = await getBrowserHttpClient();
	try {
		await api.post(
			'/auth2/web/delete',
			{},
			{
				headers: {},
			}
		);

		return true;
	} catch (error) {
		captureException(error);

		return undefined;
	}
};

interface IFetchPublicProfileParams {
	user_ids: string[];
}

interface IFetchPublicProfileRequest {
	user_ids: string[];
}
export const fetchPublicProfile = async ({ user_ids }: IFetchPublicProfileParams): Promise<IUserProfile[] | null> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<{ data: IUserProfile[] }, IFetchPublicProfileRequest>(
			'/client/user/public_profile',
			{
				user_ids,
			},
			{
				headers: {},
			}
		);

		return result.data;
	} catch (error) {
		captureException(error);

		return null;
	}
};
