import { DEFAULT_BBOX, DEFAULT_CENTER, DEFAULT_ZOOM } from '@components/QMap3/constants';
import queryString from 'query-string';
import { ESearchType, ISearchFilters, TTypePageContent } from '@main/src/types/pages/search';
import { ICommonData } from '@main/src/types/common';

export const parseBounds = (bounds: string[] | number[]): [[number, number], [number, number]] | null => {
	if (typeof bounds[0] === 'string') {
		const [f, l] = bounds;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const splitF = f.split(',');
		const splitL = (l as string).split(',');

		if (splitF.length === 2 && splitL.length === 2) {
			return [
				[Number(splitF[0]), Number(splitF[1])],
				[Number(splitL[0]), Number(splitL[1])],
			];
		}
	} else {
		if (typeof bounds[0] === 'number') {
			return [
				[Number(bounds[0]), Number(bounds[1])],
				[Number(bounds[2]), Number(bounds[3])],
			];
		}
	}

	return null;
};
export const getSearchParams = (common: ICommonData, url: string): ISearchFilters => {
	try {
		const parsedUrl = queryString.parse(decodeURI(url), {
			arrayFormat: 'comma',
			parseNumbers: true,
			parseBooleans: true,
		});

		const result: ISearchFilters = {
			zoom: (parsedUrl.zoom as number) || DEFAULT_ZOOM,
			center: (parsedUrl.center as [number, number]) || null,
		};

		// if (parsedUrl.center) {
		// 	const urlCenter = parsedUrl.center as [number, number];
		// 	result.center = [urlCenter[1], urlCenter[0]];
		// } else {
		// 	result.center = [DEFAULT_CENTER[1], DEFAULT_CENTER[0]];
		// }

		if (parsedUrl.bounds) {
			result.bbox = parseBounds(parsedUrl.bounds as string[]) || DEFAULT_BBOX;
		}

		if (parsedUrl.type) {
			result.type = parsedUrl.type as ESearchType;
		} else {
			result.type = ESearchType.any;
		}

		if (parsedUrl.tags) {
			if (typeof parsedUrl.tags === 'string') {
				result.tags = [parsedUrl.tags];
			} else {
				result.tags = parsedUrl.tags as string[];
			}
		}

		if (parsedUrl.locationName) {
			result.locationName = parsedUrl.locationName as string;
		}

		if (parsedUrl.typePage) {
			result.typePage = parsedUrl.typePage as TTypePageContent;
		}

		return result;
	} catch (error) {
		// TODO send to sentry
		// eslint-disable-next-line no-console
		console.log('error parse /search page params', error);
	}

	return {};
};

export const numberRounding = (int: number) => {
	const number = String(Math.round(int));
	if (number.length > 1) return `${Math.round(+number / 10)}0`;

	return number;
};
