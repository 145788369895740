import { captureException } from '@sentry/core';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { ISupplierDetail, NearbyCardsWithPaging } from '@main/src/types/supplier';
import { supplierCardMapper, supplierPageMapper } from '@app/api/services/node/supplier/mapper';
import {
	IFetchSupplierInfoParams,
	IFetchSupplierCardParams,
	IRequestCardsParams,
	IRequestParams,
	IResponseData,
	NearbyCardsNode,
} from '@app/api/services/node/supplier/types';
import { DEFAULT_ITEMS_ON_PAGE } from '@app/constants/listing';
import { fetchCurrencyRates } from '@app/api/services/node/currencyRates';

export const fetchSupplierInfo = async ({
	supplierId,
	locale,
}: IFetchSupplierInfoParams): Promise<ISupplierDetail | null> => {
	const api = await getBrowserHttpClient();

	try {
		const result = await api.post<IResponseData, IRequestParams>(
			'/client/serp/org-info',
			{
				lang: locale,
				org_id: supplierId,
			},
			{
				headers: {},
			}
		);

		return supplierPageMapper({
			result: result,
		});
	} catch (error) {
		captureException(error);

		return null;
	}
};

export const fetchSupplierCard = async ({
	supplierId,
	page,
	locale,
	currency,
}: IFetchSupplierCardParams): Promise<NearbyCardsWithPaging | null> => {
	const api = await getBrowserHttpClient();

	try {
		const currencyRates = await fetchCurrencyRates();
		const result = await api.post<NearbyCardsNode, IRequestCardsParams>(
			'/client/serp/org-page',
			{
				org_id: supplierId,
				page_size: DEFAULT_ITEMS_ON_PAGE,
				page: page - 1,
				lang: locale,
			},
			{
				headers: {},
			}
		);

		const { cards, cards_cnt } = supplierCardMapper(result, currency, currencyRates);
		const allPages = Math.ceil(Number(cards_cnt || 0) / DEFAULT_ITEMS_ON_PAGE);

		return {
			cards,
			paging: {
				page,
				allPages,
				countItems: cards_cnt,
			},
		};
	} catch (error) {
		captureException(error);

		return null;
	}
};
