import { TOrderCard } from 'src/types/orders';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { IFetchOrdersServiceParams, IOrderResponse } from '@app/api/services/shared/orders/types';
import { mapper } from '@app/api/services/shared/orders/mapper';
import { captureException } from '@sentry/core';
import { fetchCurrencyRates } from '@app/api/services/node/currencyRates';

export const fetchOrders = async ({ currency }: IFetchOrdersServiceParams): Promise<TOrderCard[]> => {
	const api = await getBrowserHttpClient();

	try {
		const currencyRates = await fetchCurrencyRates();
		const result = await api.post<{ data: IOrderResponse[] }>(
			'/client/order/list',
			{},
			{
				headers: {},
			}
		);

		return mapper({ orders: result.data, currency, currencyRates });
	} catch (error) {
		captureException(error);

		return [];
	}
};
