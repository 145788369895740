import { IS_DEV } from '@app/constants';

export const DEFAULT_ZOOM = 14;
export const AFTER_LOCATION_SEARCH_ZOOM = 10;

export const DEFAULT_CENTER: [number, number] = [55.75210671455454, 37.62139604419643];
export const DEFAULT_BBOX: [[number, number], [number, number]] = [
	[55.47293371250433, 37.09224558532611],
	[55.98143073076355, 38.143053783899006],
];

export const DEFAULT_OPTIONS: YandexMap.IMapOptions = {
	avoidFractionalZoom: true,
	scrollZoomSmooth: true,
	layerLoadTilesInAction: true,
	autoFitToViewport: 'always',
	suppressMapOpenBlock: true,
	yandexMapAutoSwitch: false,
	yandexMapDisablePoiInteractivity: true,
	minZoom: 6,
	maxZoom: 18,
};

export const ITEMS_PER_PAGE = IS_DEV ? 4 : 12;

export const MAP_STYLE: { [key: string]: string } = {
	en: 'https://api.maptiler.com/maps/f9fc066d-267b-4922-a008-abc8ab3606c8/style.json?key=3UHVfrMW1thFD55SUmOi',
	ru: 'https://api.maptiler.com/maps/b8072c48-cefd-4b9e-bdef-9f7b7564d18f/style.json?key=3UHVfrMW1thFD55SUmOi',
};

export const DISTANCE_MIN_SEARCH_HOTEL = 60;
export const DISTANCE_MIN_BBOX_HOTEL = 90;
