import dayjs, { Dayjs } from 'dayjs';

export const dayjsRange = (from: Dayjs, to: Dayjs): Dayjs[] => {
	const list: Dayjs[] = [];
	let current = dayjs(from);
	while (current.isBefore(to)) {
		list.push(current);
		current = current.add(1, 'day');
	}

	return list;
};
