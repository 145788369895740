import { getBrowserHttpClient } from '@app/api/browserHttpApi';

import {
	INotificationSaveEmailReq,
	INotifyReadEmailResp,
	INotifyReadProfileResp,
	INotifySaveProfileReq,
} from './types';
import { captureException } from '@sentry/core';
import { IEmailTypes, INotificationBlock } from '@main/src/types/profile/notifications';
import { notificationsReadMapper, readEmailsMapper } from './mapper';

export const fetchNotificationsRead = async (): Promise<INotificationBlock[] | null> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<INotifyReadProfileResp, { org_id: string | undefined }>(
			'/notify/read/profile',
			{
				org_id: undefined,
			},
			{
				headers: {},
			}
		);

		return notificationsReadMapper(result.data);
	} catch (error) {
		captureException(error);

		return null;
	}
};

export const fetchNotificationsSave = async (data: INotifySaveProfileReq): Promise<boolean> => {
	const api = await getBrowserHttpClient();
	try {
		await api.post<void, INotifySaveProfileReq>('/notify/save/profile', data, {
			headers: {},
		});

		return true;
	} catch (error) {
		captureException(error);

		return false;
	}
};

export const fetchNotificationReadEmail = async (): Promise<IEmailTypes | null> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<INotifyReadEmailResp, { org_id: string | undefined }>(
			'/notify/read/email',
			{
				org_id: undefined,
			},
			{
				headers: {},
			}
		);

		return readEmailsMapper(result.data);
	} catch (error) {
		captureException(error);

		return null;
	}
};

export const fetchNotificationSaveEmail = async (data: INotificationSaveEmailReq): Promise<boolean> => {
	const api = await getBrowserHttpClient();
	try {
		await api.post<void, INotificationSaveEmailReq>('/notify/save/email', data, {
			headers: {},
		});

		return true;
	} catch (error) {
		captureException(error);

		return false;
	}
};
