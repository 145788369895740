import { EActivityType } from '@main/src/types/entities/activity';
import { IPrice } from '@main/src/types/entities/price';
import { parsePrice } from '@common/helpers/components';

export const getTextPrice = (type: EActivityType, level: number | null, price: IPrice): string | null => {
	if (type === EActivityType.restaurant) {
		switch (level) {
			case 6:
				return 'expensive';
			case 5:
				return 'expensive';
			case 4:
				return 'middle';
			case 3:
				return 'middle';
			case 2:
				return 'little';
			case 1:
				return 'little';
			default:
				return '';
		}
	}
	if (type === EActivityType.activity || type === EActivityType.accommodation) {
		return parsePrice(price);
	}

	return null;
};
