export const DATA = {
	ru: {
		data: {
			page: {
				createdAt: '2022-08-18T06:15:37.553Z',
				updatedAt: '2022-10-28T15:39:41.313Z',
				publishedAt: '2022-08-18T06:15:39.083Z',
				locale: 'ru',
				data: {
					faq: {
						title: 'Часто задаваемые <span>вопросы</span>',
						questions: [
							{
								question: '1. В каких направлениях туризма специализируется QVEDO?',
								response:
									'В настоящий момент QVEDO специализируется на букинге отелей и гостиниц, экскурсионном и активном туризме, пакетных турах, морских круизах и ресторанном бизнесе.',
							},
							{
								question: '2. В каких странах и регионах планирует присутствовать QVEDO?',
								response:
									'Мы стартуем с Российском Федерации, и продолжаем свое развитие в таких регионах как: Азия, Европа, Латинская Америка и Африка.',
							},
							{
								question: '3. На каких платформах присутствует QVEDO?',
								response:
									'QVEDO - это тревел суперапп, который можно найти в RuStore, Google Play, App Store, HUAWEI, AppGallery, а также доступна Web версия сайта. Регистрируясь у нас, вы присутствуете везде.',
							},
							{
								question: '4. Остались еще вопросы?',
								response: 'Напишите на <a href="mailto:support@qvedo.com">support@qvedo.com</a>',
							},
						],
						description:
							'Мы постоянно вовлечены и поддерживаем коммуникацию 24/7 с поставщиками и с пользователями услуг',
					},
					company: {
						title: 'Мы поможем каждому <span>локальному</span> бизнесу стать <span>глобальным.</span>',
						mission: {
							title: 'Наша миссия:',
							description:
								'<span>Мы объединяем людей</span>, желающих получить впечатление с организаторами туров и экскурсий, специалистами гостиничного и ресторанного бизнесов по <span>всему миру.</span>  Мы верим, что ваше впечатление передаст пользователям новый опыт, донесет культурную и социальную ценность. Задача QVEDO - ярко и интересно рассказать всему миру о ваших предложениях.',
						},
						description:
							'QVEDO — это маркетплейс впечатлений, который объединяет людей и поставщиков услуг по всему миру. Регистрируясь в нашей системе, вы получаете возможность заявить о себе огромной аудитории по России и за ее пределами. Мы постоянно расширяем территорию своего присутствия, поэтому вы всегда развиваетесь и расширяете границы бизнеса вместе с нами.',
					},
					listing: {
						items: [
							{
								title: '<span>Ответственное продвижение</span>',
								description:
									'В QVEDO интеллектуальная система продвижения предложений. Когда ваше впечатление, услуга вызывает интерес, вас увидит еще большее количество пользователей. Вот почему для нас важны отзывы пользователей и работа с репутацией.',
							},
							{
								title: '<span>Поможем найти своего клиента</span>',
								description:
									'Для пользователей в QVEDO реализована система персональных рекомендаций. Наша платформа будет таргетированно показывать ваше предложение заинтересованной группе пользователей на основе их интересов, прошлого опыта и профиля.',
							},
							{
								title: '<span>Простота использования и регистрации</span>',
								description:
									'Для удобства коммуникации мы сократили пользовательский путь. Пользователю достаточно трех простых шагов для бронирования услуги. Для вас все регистрационные формы интуитивно понятны и помогают выгодно презентовать предложения будущим клиентам.',
							},
						],
						title: 'Почему стоит присоединиться к QVEDO?',
					},
					contacts: {
						items: { vk: 'https://vk.com/qvedo', email: 'hello@qvedo.com', telegram: 'https://t.me/qvedo' },
						title: 'Контакты',
						address:
							'ООО КВЕДО ИНН 5009127836 КПП 500901001 ОГРН 1215000057810 142002, МОСКОВСКАЯ ОБЛ., ДОМОДЕДОВО Г.О., ДОМОДЕДОВО Г., МКР. ЗАПАДНЫЙ, ТЕКСТИЛЬЩИКОВ УЛ., Д. 41Б, ПОМЕЩ. 6',
						cooperation: {
							items: {
								B2G: { email: 'region@qvedo.com', title: 'Для В2G' },
								clients: { email: 'hello@qvedo.com', title: 'Для клиентов' },
								business: { email: 'partners@qvedo.com', title: 'Бизнес предложения' },
								marketing: { email: 'marketing@qvedo.com', title: 'По рекламе и маркетингу' },
							},
							title: 'Связаться с нами',
						},
						description: 'Связаться с нами можно несколькими способами. Выберите наиболее подходящий вариант.',
					},
					howItWorks: {
						items: [
							{
								title: '<span>1. Бронирование</span>',
								description: 'Достаточно трех простых шагов для подбора, ознакомления и бронирования активности',
							},
							{
								title: '<span>2. Подтверждение</span>',
								description:
									'Один простой шаг Поставщика -  это подтверждение  бронирования, остальное мы берем на себя',
							},
							{
								title: '<span>3. Online Оплата</span>',
								description: 'Быстрая и безопасная online оплата с поддержкой платежных систем МИР, VISA и MASTERCARD',
							},
						],
						title: 'Насколько <span>легко</span> это работает',
						description: 'Всего три простых шага для совершения транзакции',
					},
				},
			},
		},
	},
	en: {
		data: {
			page: {
				createdAt: '2022-08-18T06:15:51.377Z',
				updatedAt: '2022-09-29T09:00:14.358Z',
				publishedAt: '2022-08-18T06:15:52.815Z',
				locale: 'en',
				data: {
					faq: {
						title: '<span>Frequently asked questions</span>',
						questions: [
							{
								question: '1. Is there any payment or monthly fee for posting offers on QVEDO?',
								response:
									'Using QVEDO services is totally free!  We do not charge any fees, there are no concealed payments for posting your offers and services on QVEDO.',
							},
							{
								question: '2. In which countries does QVEDO operate?',
								response:
									'We work with travel service providers in many countries across Asia, Europe, Latin America and Africa.  We constantly add new countries.',
							},
							{
								question: '3. On what platforms is QVEDO present?',
								response:
									'QVEDO is a travel superapp that can be found in the RuStore, Google Play, App Store, HUAWEI, AppGallery, and a Web version of the site is also available. By registering with us, you are present everywhere.',
							},
							{
								question: '4. Any more questions?',
								response:
									'Our Help center operates 24/7. Send an email to <a href="mailto:support@qvedo.com">support@qvedo.com</a>',
							},
						],
						description: 'We are constantly engaged and communicating 24/7 with providers and service users',
					},
					company: {
						title: 'We help every <span>local</span> business become <span>global.</span>',
						mission: {
							title: 'Our mission:',
							description:
								"<span>We connect travelers</span> looking for local experiences with activity vendors, tour organizers, hotel and restaurant operators around <span>the world</span>. We believe that your offers will provide users with new impressions, add cultural and social values. QVEDO's mission is to make your offerings and services stand out to the world.",
						},
						description:
							'QVEDO is a travel marketplace that brings together activities and service providers and tourists around the world. By registering in our system you get the opportunity to make yourself known to a huge audience across the globe. We are constantly expanding to new countries.  Your business boundaries will be expanding with us!',
					},
					listing: {
						items: [
							{
								title: '<span>Effective promotion of your services</span>',
								description:
									"QVEDO has an intelligent system for promoting offers and services. When your services generate interest, you will be seen by ever growing numbers of users. That's why user feedback and constant improvement of reputation are important to us.",
							},
							{
								title: '<span>We will help you to find new customers</span>',
								description:
									'QVEDO has a system of personalized recommendations for users. Our platform will specifically target offers to users based on their interests, past experience and profile.',
							},
							{
								title: '<span>Easy to register and use</span>',
								description:
									'It is simple and easy to register on QVEDO platform.  It will take up to 5 minutes. We will also provide guidance if you need help.',
							},
						],
						title: 'Why join QVEDO?',
					},
					contacts: {
						items: { vk: 'https://vk.com/qvedo', email: 'hello@qvedo.com', telegram: 'https://t.me/qvedo' },
						title: 'Our contacts',
						cooperation: {
							items: {
								B2G: { email: 'regions@qvedo.com', title: 'For В2G' },
								clients: { email: 'hello@qvedo.com', title: 'For customers' },
								business: { email: 'partners@qvedo.com', title: 'Business offers' },
								marketing: { email: 'hello@qvedo.com', title: 'Advertising and marketing' },
							},
							title: 'Contact Us',
						},
						description: 'There are several ways to contact us. Choose the most suitable option.',
					},
					howItWorks: {
						items: [
							{
								title: '<span>1. Booking</span>',
								description: 'When a traveler books your activity or service, vendor gets notification of the booking',
							},
							{ title: '<span>2. Confirmation</span>', description: 'Vendor confirms the booking to traveler' },
							{
								title: '<span>3. Online Payment</span>',
								description: 'Fast and secure online payment with support of all major payment systems',
							},
						],
						title: '<span>How QVEDO works</span>',
						description: 'Three simple steps',
					},
				},
			},
		},
	},
};
