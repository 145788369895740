export type TCategory =
	| 'ctg.cafe'
	| 'ctg.coffee_house'
	| 'ctg.pastry_shop'
	| 'ctg.family_cafe'
	| 'ctg.nightlife'
	| 'ctg.pub'
	| 'ctg.fast_food'
	| 'ctg.sushi'
	| 'ctg.burgers'
	| 'ctg.pizza'
	| 'ctg.food_court'
	| 'ctg.food_hall'
	| 'ctg.hookah_lounge'
	| 'ctg.wine_bar'
	| 'ctg.gourmet_market'
	| 'ctg.anti_cafe';

export type TCuisine =
	| 'ct.aus'
	| 'ct.aut'
	| 'ct.aze'
	| 'ct.int_asian'
	| 'ct.usa'
	| 'ct.int_arabic'
	| 'ct.arg'
	| 'ct.arm'
	| 'ct.int_african'
	| 'ct.int_baltic'
	| 'ct.bgd'
	| 'ct.bel'
	| 'ct.int_mideast'
	| 'ct.bra'
	| 'ct.gbr'
	| 'ct.hun'
	| 'ct.int_winebar'
	| 'ct.int_east_european'
	| 'ct.vnm'
	| 'ct.int_hawaii'
	| 'ct.int_gourmet'
	| 'ct.int_gastropub'
	| 'ct.nld'
	| 'ct.hkg'
	| 'ct.grc'
	| 'ct.int_fire'
	| 'ct.int_grill'
	| 'ct.geo'
	| 'ct.dnk'
	| 'ct.int_european'
	| 'ct.egy'
	| 'ct.int_healthy'
	| 'ct.int_isr'
	| 'ct.int_indig'
	| 'ct.ind'
	| 'ct.idn'
	| 'ct.irl'
	| 'ct.esp'
	| 'ct.ita'
	| 'ct.int_caucasian'
	| 'ct.int_southern'
	| 'ct.khm'
	| 'ct.can'
	| 'ct.int_caribbean'
	| 'ct.chn'
	| 'ct.col'
	| 'ct.kor'
	| 'ct.cri'
	| 'ct.cub'
	| 'ct.int_latin'
	| 'ct.lva'
	| 'ct.lbn'
	| 'ct.mys'
	| 'ct.mar'
	| 'ct.int_global'
	| 'ct.mex'
	| 'ct.mng'
	| 'ct.int_seafood'
	| 'ct.int_naples'
	| 'ct.deu'
	| 'ct.npl'
	| 'ct.nzl'
	| 'ct.nor'
	| 'ct.int_brewery'
	| 'ct.pak'
	| 'ct.int_persian'
	| 'ct.per'
	| 'ct.int_beer'
	| 'ct.int_polynesia'
	| 'ct.pol'
	| 'ct.prt'
	| 'ct.int_rome'
	| 'ct.rou'
	| 'ct.rus'
	| 'ct.sgp'
	| 'ct.int_nordic'
	| 'ct.svn'
	| 'ct.int_mid'
	| 'ct.int_steak'
	| 'ct.int_sichuan'
	| 'ct.int_taiwan'
	| 'ct.tha'
	| 'ct.int_tibet'
	| 'ct.int_tuscany'
	| 'ct.tun'
	| 'ct.tur'
	| 'ct.uzb'
	| 'ct.ukr'
	| 'ct.phl'
	| 'ct.fra'
	| 'ct.int_fusion'
	| 'ct.hrv'
	| 'ct.cze'
	| 'ct.chl'
	| 'ct.int_shanghai'
	| 'ct.swe'
	| 'ct.che'
	| 'ct.int_scottish'
	| 'ct.lka'
	| 'ct.ecu'
	| 'ct.eth'
	| 'ct.int_south_asia'
	| 'ct.jpn'
	| 'ct.int_jpn_fusion'
	| 'ct.int_author'
	| 'ct.int_mix'
	| 'ct.int_veg'
	| 'ct.int_panasian';

export type TPriceLvl = 'price.below_avg' | 'price.avg' | 'price.above_avg';

export type TMenu =
	| 'attr.vegetarian'
	| 'attr.vegan'
	| 'attr.halal'
	| 'attr.kosher'
	| 'attr.gluten_free'
	| 'attr.lean'
	| 'attr.lunch'
	| 'attr.kids_menu';

export type TOther =
	| 'attr.vegetarian'
	| 'attr.vegan'
	| 'attr.halal'
	| 'attr.kosher'
	| 'attr.gluten_free'
	| 'attr.lean'
	| 'attr.wifi'
	| 'attr.lunch'
	| 'attr.playroom'
	| 'attr.kids_entertainer'
	| 'attr.delivery'
	| 'attr.togo'
	| 'attr.vip_lounge'
	| 'attr.hookah'
	| 'attr.fireplace'
	| 'attr.interior'
	| 'attr.nice_view'
	| 'attr.24h'
	| 'attr.terrace'
	| 'attr.panoramic'
	| 'attr.balcony'
	| 'attr.pets'
	| 'attr.roof'
	| 'attr.parking'
	| 'attr.late_breakfast'
	| 'attr.hotel'
	| 'attr.confectionery'
	| 'attr.brewery'
	| 'attr.kids_wc'
	| 'attr.changing_room'
	| 'attr.cigar_room'
	| 'attr.hookah_lounge'
	| 'attr.meeting_room'
	| 'attr.tea_ceremony'
	| 'attr.banquet'
	| 'attr.romantic'
	| 'attr.birthday_discount'
	| 'attr.master_class'
	| 'attr.live_music'
	| 'attr.dj'
	| 'attr.pool_table'
	| 'attr.dance'
	| 'attr.karaoke'
	| 'attr.boardgames'
	| 'attr.sport'
	| 'attr.striptease'
	| 'attr.show'
	| 'attr.tasting_menu'
	| 'attr.kids_free'
	| 'attr.online'
	| 'attr.wheelchair'
	| 'attr.breakfast'
	| 'attr.cork_fee'
	| 'attr.chef_menu'
	| 'attr.on_water'
	| 'attr.interactive_menu';

export enum ESearchType {
	any = 'any',
	activity = 'activity',
	restaurant = 'restaurant',
	attraction = 'attraction',
	accommodation = 'accommodation',
	packageTour = 'packageTour',
	cruise = 'cruise',
	publicPlace = 'publicPlace',
}

export type TTypePageContent = 'map' | 'list';

export interface ISearchFilters {
	bbox?: [[number, number], [number, number]];
	zoom?: number;
	center?: [number, number];
	locationName?: string;
	type?: ESearchType;
	tags?: string[];
	typePage?: TTypePageContent;
}

export interface ISearchProps {
	filters: ISearchFilters;
}
