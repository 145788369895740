import { IEmailTypes, INotificationBlock } from '@main/src/types/profile/notifications';
import { INotifyReadEmailResp, INotifyReadProfileResp } from './types';

export const readEmailsMapper = (arr: INotifyReadEmailResp['data']): IEmailTypes => {
	const com = arr.find(i => i.email_type === 'com')?.email;
	const order = arr.find(i => i.email_type === 'order')?.email;

	return {
		com,
		order,
	};
};

export const notificationsReadMapper = (arr: INotifyReadProfileResp['data']): INotificationBlock[] => {
	const market = arr.find(i => i.profile === 'market') || {
		profile: 'market',
		sms: false,
		push: false,
		email: null,
	};
	const chat = arr.find(i => i.profile === 'chat') || {
		profile: 'chat',
		sms: false,
		push: true,
		email: null,
	};
	const order = arr.find(i => i.profile === 'order') || {
		profile: 'order',
		sms: false,
		push: false,
		email: null,
	};

	return [market, chat, order];
};
