export const CARD_TYPE: Record<string, {ns: string, t: string, key: string, priority: number}> = {
  '1': {ns: 'api_cat', t: 'type.restaurant.name', key: 'restaurant', priority: 2},
  '2': {ns: 'api_cat', t: 'type.attraction.name', key: 'attraction', priority: 4},
  '3': {ns: 'api_cat', t: 'type.accommodation.name', key: 'accommodation', priority: 3},
  '4': {ns: 'api_cat', t: 'type.public_place.name', key: 'public_place', priority: 5},
  '5': {ns: 'api_cat', t: 'type.activity.name', key: 'activity', priority: 1},
};

export const CARD_RATING = {
  'rat.0': {ns: 'api_cat', t: 'rat.0.name', key: 'rat.0'}, // Любой рейтинг (0+)
  'rat.60': {ns: 'api_cat', t: 'rat.60.name', key: 'rat.60'}, // Средне (3+)
  'rat.80': {ns: 'api_cat', t: 'rat.80.name', key: 'rat.80'}, // Хорошо (4+)
  'rat.90': {ns: 'api_cat', t: 'rat.90.name', key: 'rat.90'}, // Отлично (4.5+)
};

export const CARD_PRICE_LEVEL = {
  'pl.2': {ns: 'api_cat', t: 'price_level.level_2.name', key: 'pl.below_average'},
  'pl.4': {ns: 'api_cat', t: 'price_level.level_4.name', key: 'pl.average'},
  'pl.6': {ns: 'api_cat', t: 'price_level.level_6.name', key: 'pl.above_average'},
};

export const CARD_RESTAURANT_CTG = {
  't.1': {ns: 'api_cat', t: 'restaurant.search_ctg.bakery_dessert.name', key: 'ctg.bakery_dessert'},
  't.2': {ns: 'api_cat', t: 'restaurant.search_ctg.coffee_tea.name', key: 'ctg.coffee_tea'},
  't.3': {ns: 'api_cat', t: 'restaurant.search_ctg.fast_food.name', key: 'ctg.fast_food'},
  't.4': {ns: 'api_cat', t: 'restaurant.search_ctg.nightlife.name', key: 'ctg.nightlife'},
  't.5': {ns: 'api_cat', t: 'restaurant.search_ctg.restaurant.name', key: 'ctg.restaurant'},
  't.6': {ns: 'api_cat', t: 'restaurant.search_ctg.specialty_food.name', key: 'ctg.specialty_food'},
};

export const CARD_RESTAURANT_CUISINE = {
  't.7': {ns: 'api_cat', t: 'restaurant.ct.int_caucasian.name', key: 'cuisine.caucasian'},
  't.8': {ns: 'api_cat', t: 'restaurant.ct.chn.name', key: 'cuisine.chinese'},
  't.9': {ns: 'api_cat', t: 'restaurant.ct.int_ethnic.name', key: 'cuisine.ethnic'},
  't.10': {ns: 'api_cat', t: 'restaurant.ct.int_european.name', key: 'cuisine.european'},
  't.11': {ns: 'api_cat', t: 'restaurant.ct.int_fusion.name', key: 'cuisine.fusion'},
  't.12': {ns: 'api_cat', t: 'restaurant.ct.int_global.name', key: 'cuisine.international'},
  't.13': {ns: 'api_cat', t: 'restaurant.ct.ita.name', key: 'cuisine.italian'},
  't.14': {ns: 'api_cat', t: 'restaurant.ct.jpn.name', key: 'cuisine.japanese'},
  't.15': {ns: 'api_cat', t: 'restaurant.ct.int_mid.name', key: 'cuisine.mediterranean'},
  't.16': {ns: 'api_cat', t: 'restaurant.ct.mex.name', key: 'cuisine.mexican'},
  't.17': {ns: 'api_cat', t: 'restaurant.ct.int_mideast.name', key: 'cuisine.middle_east'},
  't.18': {ns: 'api_cat', t: 'restaurant.ct.int_nordic.name', key: 'cuisine.nordic'},
  't.19': {ns: 'api_cat', t: 'restaurant.ct.usa.name', key: 'cuisine.pan_american'}, // TODO: заменить перевод
  't.20': {ns: 'api_cat', t: 'restaurant.ct.int_asian.name', key: 'cuisine.pan_asian'},
  't.21': {ns: 'api_cat', t: 'restaurant.ct.int_east_european.name', key: 'cuisine.slavic'}, // TODO: улучшить перевод
  't.22': {ns: 'api_cat', t: 'restaurant.ct.tha.name', key: 'cuisine.thai'},
};

export const CARD_RESTAURANT_DIET = {
  't.23': {ns: 'api_cat', t: 'restaurant.diet.gluten_free.name', key: 'diet.gluten_free'},
  't.24': {ns: 'api_cat', t: 'restaurant.diet.halal.name', key: 'diet.halal'},
  't.25': {ns: 'api_cat', t: 'restaurant.diet.kosher.name', key: 'diet.kosher'},
  't.26': {ns: 'api_cat', t: 'restaurant.diet.vegetarian.name', key: 'diet.vegi'},
};

export const CARD_RESTAURANT_THEME = {
  't.41': {ns: 'api_cat', t: 'restaurant.theme.alcohol.name', key: 'theme.alcohol'},
  't.42': {ns: 'api_cat', t: 'restaurant.theme.burger.name', key: 'theme.burger'},
  't.43': {ns: 'api_cat', t: 'restaurant.theme.healthy.name', key: 'theme.healthy'},
  't.44': {ns: 'api_cat', t: 'restaurant.theme.meat.name', key: 'theme.meat'},
  't.45': {ns: 'api_cat', t: 'restaurant.theme.pizza.name', key: 'theme.pizza'},
  't.46': {ns: 'api_cat', t: 'restaurant.theme.seafood.name', key: 'theme.seafood'},
};

export const CARD_RESTAURANT_FILTERS = {
  't.27': {ns: 'api_cat', t: 'restaurant.filters.author.name', key: 'filters.author'},
  't.28': {ns: 'api_cat', t: 'restaurant.filters.dance_floor.name', key: 'filters.dance_floor'},
  't.29': {ns: 'api_cat', t: 'restaurant.filters.entertainment.name', key: 'filters.entertainment'},
  't.30': {ns: 'api_cat', t: 'restaurant.filters.family.name', key: 'filters.family'},
  't.31': {ns: 'api_cat', t: 'restaurant.filters.has_disabled_support.name', key: 'filters.has_disabled_support'},
  't.32': {ns: 'api_cat', t: 'restaurant.filters.has_kids.name', key: 'filters.has_kids'},
  't.33': {ns: 'api_cat', t: 'restaurant.filters.has_parking.name', key: 'filters.has_parking'},
  't.34': {ns: 'api_cat', t: 'restaurant.filters.hookah.name', key: 'filters.hookah'},
  't.35': {ns: 'api_cat', t: 'restaurant.filters.large_groups.name', key: 'filters.large_groups'},
  't.36': {ns: 'api_cat', t: 'restaurant.filters.live_music.name', key: 'filters.live_music'},
  't.37': {ns: 'api_cat', t: 'restaurant.filters.outdoor.name', key: 'filters.outdoor'},
  't.38': {ns: 'api_cat', t: 'restaurant.filters.scenic.name', key: 'filters.scenic'},
  't.39': {ns: 'api_cat', t: 'restaurant.filters.sport.name', key: 'filters.sport'},
  't.40': {ns: 'api_cat', t: 'restaurant.filters.view.name', key: 'filters.view'},
};

export const CARD_ATTRACTION_CTG = {
  't.1': {ns: 'api_cat', t: 'attraction.ctg.anomaly.name', key: 'ctg.anomaly'},
  't.2': {ns: 'api_cat', t: 'attraction.ctg.arch.name', key: 'ctg.arch'},
  't.3': {ns: 'api_cat', t: 'attraction.ctg.art.name', key: 'ctg.art'},
  't.4': {ns: 'api_cat', t: 'attraction.ctg.fun.name', key: 'ctg.fun'},
  't.5': {ns: 'api_cat', t: 'attraction.ctg.landmark.name', key: 'ctg.landmark'},
  't.6': {ns: 'api_cat', t: 'attraction.ctg.memorial.name', key: 'ctg.memorial'},
  't.7': {ns: 'api_cat', t: 'attraction.ctg.museum.name', key: 'ctg.museum'},
  't.8': {ns: 'api_cat', t: 'attraction.ctg.place.name', key: 'ctg.place'},
  't.9': {ns: 'api_cat', t: 'attraction.ctg.religion.name', key: 'ctg.religion'},
  't.19': {ns: 'api_cat', t: 'attraction.ctg.shopping.name', key: 'ctg.shopping'}, // TODO: new
};

export const CARD_ATTRACTION_FILTERS = {
  't.10': {ns: 'api_cat', t: 'attraction.filters.free_entry.name', key: 'filters.free_entry'}, // TODO: new
  't.11': {ns: 'api_cat', t: 'attraction.filters.kids_friendly.name', key: 'filters.kids_friendly', not: 't.18'}, // TODO: new
  't.12': {ns: 'api_cat', t: 'attraction.filters.scenic.name', key: 'filters.scenic'}, // TODO: new
  't.13': {ns: 'api_cat', t: 'attraction.filters.sacred.name', key: 'filters.sacred'}, // TODO: new
  't.14': {ns: 'api_cat', t: 'attraction.filters.relaxing.name', key: 'filters.relaxing'}, // TODO: new
  't.15': {ns: 'api_cat', t: 'attraction.filters.flat_surface.name', key: 'filters.flat_surface'}, // TODO: new
  't.16': {ns: 'api_cat', t: 'attraction.filters.has_parking.name', key: 'filters.has_parking'}, // TODO: new
  't.17': {ns: 'api_cat', t: 'attraction.filters.has_disabled_support.name', key: 'filters.has_disabled_support'}, // TODO: new
  't.18': {
    ns: 'api_cat',
    t: 'attraction.filters.no_kids_friendly.name',
    key: 'filters.no_kids_friendly',
    not: 't.11',
    hidden: true,
  }, // TODO: new
};

export const CARD_ACCOMMODATION_KD = {
  'kd.1': {ns: 'api_cat', t: 'accommodation.kd.apart_hotel.name', key: 'apart_hotel'},
  'kd.2': {ns: 'api_cat', t: 'accommodation.kd.apartment.name', key: 'apartment'},
  'kd.3': {ns: 'api_cat', t: 'accommodation.kd.bnb.name', key: 'bnb'},
  'kd.4': {ns: 'api_cat', t: 'accommodation.kd.boutique_and_design.name', key: 'boutique_and_design'},
  'kd.5': {ns: 'api_cat', t: 'accommodation.kd.camping.name', key: 'camping'},
  'kd.6': {ns: 'api_cat', t: 'accommodation.kd.castle.name', key: 'castle'},
  'kd.7': {ns: 'api_cat', t: 'accommodation.kd.cottages_and_houses.name', key: 'cottages_and_houses'},
  'kd.8': {ns: 'api_cat', t: 'accommodation.kd.farm.name', key: 'farm'},
  'kd.9': {ns: 'api_cat', t: 'accommodation.kd.glamping.name', key: 'glamping'},
  'kd.10': {ns: 'api_cat', t: 'accommodation.kd.guesthouse.name', key: 'guesthouse'},
  'kd.11': {ns: 'api_cat', t: 'accommodation.kd.hostel.name', key: 'hostel'},
  'kd.12': {ns: 'api_cat', t: 'accommodation.kd.hotel.name', key: 'hotel'},
  'kd.13': {ns: 'api_cat', t: 'accommodation.kd.mini_hotel.name', key: 'mini_hotel'},
  'kd.14': {ns: 'api_cat', t: 'accommodation.kd.resort.name', key: 'resort'},
  'kd.15': {ns: 'api_cat', t: 'accommodation.kd.sanatorium.name', key: 'sanatorium'},
  'kd.16': {ns: 'api_cat', t: 'accommodation.kd.villas_and_bungalows.name', key: 'villas_and_bungalows'},
};

export const CARD_ACCOMMODATION_ST = {
  'st.1': {ns: 'api_cat', t: 'accommodation.st.one_star.name', key: 'st.one_star'},
  'st.2': {ns: 'api_cat', t: 'accommodation.st.two_star.name', key: 'st.two_star'},
  'st.3': {ns: 'api_cat', t: 'accommodation.st.three_star.name', key: 'st.three_star'},
  'st.4': {ns: 'api_cat', t: 'accommodation.st.four_star.name', key: 'st.four_star'},
  'st.5': {ns: 'api_cat', t: 'accommodation.st.five_star.name', key: 'st.five_star'},
};

export const CARD_ACCOMMODATION_SE = {
  'se.1': {ns: 'api_cat', t: 'accommodation.filters.has_internet.name', key: 'has_internet'},
  'se.2': {ns: 'api_cat', t: 'accommodation.filters.has_airport_transfer.name', key: 'has_airport_transfer'},
  'se.3': {ns: 'api_cat', t: 'accommodation.filters.has_parking.name', key: 'has_parking'},
  'se.4': {ns: 'api_cat', t: 'accommodation.filters.air_conditioning.name', key: 'air_conditioning'},
  'se.5': {ns: 'api_cat', t: 'accommodation.filters.has_pool.name', key: 'has_pool'},
  'se.6': {ns: 'api_cat', t: 'accommodation.filters.has_meal.name', key: 'has_meal'},
  'se.7': {ns: 'api_cat', t: 'accommodation.filters.has_disabled_support.name', key: 'has_disabled_support'},
  'se.8': {ns: 'api_cat', t: 'accommodation.filters.has_business.name', key: 'has_business'},
  'se.9': {ns: 'api_cat', t: 'accommodation.filters.has_spa.name', key: 'has_spa'},
  'se.10': {ns: 'api_cat', t: 'accommodation.filters.has_jacuzzi.name', key: 'has_jacuzzi'},
  'se.11': {ns: 'api_cat', t: 'accommodation.filters.has_smoking.name', key: 'has_smoking'},
  'se.12': {ns: 'api_cat', t: 'accommodation.filters.has_kids.name', key: 'has_kids'},
  'se.13': {ns: 'api_cat', t: 'accommodation.filters.beach.name', key: 'beach'},
  'se.14': {ns: 'api_cat', t: 'accommodation.filters.has_pets.name', key: 'has_pets'},
  'se.15': {ns: 'api_cat', t: 'accommodation.filters.kitchen.name', key: 'kitchen'},
  'se.16': {ns: 'api_cat', t: 'accommodation.filters.has_fitness.name', key: 'has_fitness'},
  'se.17': {ns: 'api_cat', t: 'accommodation.filters.has_ski.name', key: 'has_ski'},
  'se.18': {ns: 'api_cat', t: 'accommodation.filters.has_ecar_charger.name', key: 'has_ecar_charger'},
};

export const CARD_ACCOMMODATION_CL = {
  'cl.0': {ns: 'api_cat', t: 'accommodation.cl.not_closed.name', key: 'cl.not_closed', not: 'cl.1'}, // `cl.0` tag is by default and is not saved in DB
  'cl.1': {ns: 'api_cat', t: 'accommodation.cl.closed.name', key: 'cl.closed', not: 'cl.0'},
};

export const CARD_PUBLIC_PLACE_CTG = {
  't.1': {ns: 'api_cat', t: 'public_place.ctg.gov.name', key: 'ctg.gov'}, // TODO: new
  't.2': {ns: 'api_cat', t: 'public_place.ctg.admin.name', key: 'ctg.admin'}, // TODO: new
  't.3': {ns: 'api_cat', t: 'public_place.ctg.ministry.name', key: 'ctg.ministry'}, // TODO: new
  't.4': {ns: 'api_cat', t: 'public_place.ctg.dept.name', key: 'ctg.dept'}, // TODO: new
  't.5': {ns: 'api_cat', t: 'public_place.ctg.city_hall.name', key: 'ctg.city_hall'}, // TODO: new
  't.6': {ns: 'api_cat', t: 'public_place.ctg.council.name', key: 'ctg.council'}, // TODO: new
  't.7': {ns: 'api_cat', t: 'public_place.ctg.public_services.name', key: 'ctg.public_services'}, // TODO: new
  't.8': {ns: 'api_cat', t: 'public_place.ctg.ngo.name', key: 'ctg.ngo'}, // TODO: new
  't.9': {ns: 'api_cat', t: 'public_place.ctg.emergency.name', key: 'ctg.emergency'}, // TODO: new
  't.10': {ns: 'api_cat', t: 'public_place.ctg.health.name', key: 'ctg.health'}, // TODO: new
  't.11': {ns: 'api_cat', t: 'public_place.ctg.culture.name', key: 'ctg.culture'}, // TODO: new
  't.12': {ns: 'api_cat', t: 'public_place.ctg.tourist_info.name', key: 'ctg.tourist_info'}, // TODO: new
};

export const CARD_ACTIVITY_CTG = {
  't.1': {ns: 'api_cat', t: 'activity.ctg.adventure.name', key: 'ctg.adventure'},
  't.2': {ns: 'api_cat', t: 'activity.ctg.culture.name', key: 'ctg.culture'},
  't.3': {ns: 'api_cat', t: 'activity.ctg.sport.name', key: 'ctg.sport'},
};

export const CARD_ACTIVITY_FILTERS = {
  't.4': {
    ns: 'api_cat',
    t: 'activity.filters.health_limit.name',
    key: 'filters.health_limit',
    not: 't.6',
    hidden: true,
  },
  't.5': {ns: 'api_cat', t: 'activity.filters.kids_friendly.name', key: 'filters.kids_friendly', not: 't.7'},
  't.6': {ns: 'api_cat', t: 'activity.filters.no_health_limit.name', key: 'filters.no_health_limit', not: 't.4'},
  't.7': {
    ns: 'api_cat',
    t: 'activity.filters.no_kids_friendly.name',
    key: 'filters.no_kids_friendly',
    not: 't.5',
    hidden: true,
  },
  't.8': {ns: 'api_cat', t: 'activity.filters.no_training.name', key: 'filters.no_training', not: 't.9'},
  't.9': {ns: 'api_cat', t: 'activity.filters.training.name', key: 'filters.training', not: 't.8', hidden: true},

  't.10': {ns: 'api_cat', t: 'activity.filters.authentic.name', key: 'filters.authentic'}, // TODO: new
  't.11': {ns: 'api_cat', t: 'activity.filters.scenic.name', key: 'filters.scenic'}, // TODO: new
  't.12': {ns: 'api_cat', t: 'activity.filters.flat_surface.name', key: 'filters.flat_surface'}, // TODO: new
  't.13': {ns: 'api_cat', t: 'activity.filters.has_disabled_support.name', key: 'filters.has_disabled_support'}, // TODO: new
};

export const CARD_ACTIVITY_GROUP_TYPE = {
  't.10': {ns: 'api_cat', t: 'activity.group_type.group.name', key: 'group_type.group'},
  't.11': {ns: 'api_cat', t: 'activity.group_type.individual.name', key: 'group_type.individual'},
};

export const CARD_ACTIVITY_HOUSING = {
  't.12': {ns: 'api_cat', t: 'activity.housing_type.included.name', key: 'housing.included'},
  't.13': {ns: 'api_cat', t: 'activity.housing_type.not_included.name', key: 'housing.not_included'},
};

export const CARD_ACTIVITY_LANG = {
  't.14': {ns: 'api_cmn', t: 'lang.ar.name', key: 'lang.ar'},
  't.15': {ns: 'api_cmn', t: 'lang.de.name', key: 'lang.de'},
  't.16': {ns: 'api_cmn', t: 'lang.en.name', key: 'lang.en'},
  't.17': {ns: 'api_cmn', t: 'lang.es.name', key: 'lang.es'},
  't.18': {ns: 'api_cmn', t: 'lang.fr.name', key: 'lang.fr'},
  't.19': {ns: 'api_cmn', t: 'lang.hi.name', key: 'lang.hi'},
  't.20': {ns: 'api_cmn', t: 'lang.ru.name', key: 'lang.ru'},
  't.21': {ns: 'api_cmn', t: 'lang.zh.name', key: 'lang.zh'},
};

export const CARD_ACTIVITY_SCHEDULE_TYPE = {
  't.22': {ns: 'api_cat', t: 'activity.schedule_type.multi_day.name', key: 'schedule_type.multi_day'},
  't.23': {ns: 'api_cat', t: 'activity.schedule_type.single_day.name', key: 'schedule_type.single_day'},
};

export const CARD_ACTIVITY_TRANSFER = {
  't.24': {ns: 'api_cat', t: 'activity.transfer_type.included.name', key: 'transfer.included'},
  't.25': {ns: 'api_cat', t: 'activity.transfer_type.not_included.name', key: 'transfer.not_included'},
};

export const SEARCH_TAGS = {
  // Restaurant
  '1': {
    ...CARD_RATING,
    ...CARD_PRICE_LEVEL,
    ...CARD_RESTAURANT_CTG,
    ...CARD_RESTAURANT_THEME,
    ...CARD_RESTAURANT_DIET,
    ...CARD_RESTAURANT_CUISINE,
    ...CARD_RESTAURANT_FILTERS,
  },

  // Attraction
  '2': {
    ...CARD_RATING,
    ...CARD_PRICE_LEVEL,
    ...CARD_ATTRACTION_CTG,
    ...CARD_ATTRACTION_FILTERS,
  },

  // Accommodation
  '3': {
    ...CARD_RATING,
    ...CARD_ACCOMMODATION_ST,
    ...CARD_ACCOMMODATION_SE,
    ...CARD_ACCOMMODATION_KD,
    ...CARD_ACCOMMODATION_CL,
  },

  // Public place
  '4': {
    ...CARD_RATING,
    ...CARD_PRICE_LEVEL,
    ...CARD_PUBLIC_PLACE_CTG,
  },

  // Activity
  '5': {
    ...CARD_RATING,
    ...CARD_PRICE_LEVEL,
    ...CARD_ACTIVITY_CTG,
    ...CARD_ACTIVITY_GROUP_TYPE,
    ...CARD_ACTIVITY_HOUSING,
    ...CARD_ACTIVITY_TRANSFER,
    ...CARD_ACTIVITY_LANG,
    ...CARD_ACTIVITY_SCHEDULE_TYPE,
    ...CARD_ACTIVITY_FILTERS,
  },
};

export function getRestaurantFilterConfig() {
  return {
    filters: {

      // Рейтинг
      rat: {
        title: {ns: 'api_cat', t: 'rat.name'},
        type: 'label_select',
        values: CARD_RATING,
      },

      // Уровень цен
      price_level: {
        title: {ns: 'api_cat', t: 'price_level.name'},
        type: 'label_select',
        values: CARD_PRICE_LEVEL,
      },

      category: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'restaurant.attr_type.ctg.name'},
        values: CARD_RESTAURANT_CTG,
      },
      cuisine: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'restaurant.attr_type.ct.name'},
        values: CARD_RESTAURANT_CUISINE,
      },
      diet: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'restaurant.attr_type.diet.name'},
        values: CARD_RESTAURANT_DIET,
      },
      theme: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'restaurant.attr_type.theme.name'},
        values: CARD_RESTAURANT_THEME,
      },
      additional_filters: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'restaurant.attr_type.additional_filters.name'},
        values: CARD_RESTAURANT_FILTERS,
      },
    },
  };
}

export function getAttractionFilterConfig() {
  return {
    filters: {
      category: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'attraction.attr_type.ctg.name'},
        values: CARD_ATTRACTION_CTG,
      },
      additional_filters: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'attraction.attr_type.additional_filters.name'},
        values: CARD_ATTRACTION_FILTERS,
      },
    },
  };
}

export function getAccommodationFilterConfig() {
  return {
    filters: {
      // Гости
      guests: {
        title: {ns: 'api_cat', t: 'accommodation.attr_type.guests.name'},
      },

      // Даты заезда и выезда
      check_dates: {
        title: {ns: 'api_cat', t: 'accommodation.attr_type.check_dates.name'},
      },

      // Цена за ночь
      price_range: {
        title: {ns: 'api_cat', t: 'accommodation.attr_type.price_range.name'},
      },

      // Тип объекта размещения
      kd: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'accommodation.attr_type.kd.name'},
        values: CARD_ACCOMMODATION_KD,
      },

      // Звездность
      st: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'accommodation.attr_type.st.name'},
        values: CARD_ACCOMMODATION_ST,
      },

      // Рейтинг
      rat: {
        title: {ns: 'api_cat', t: 'rat.name'},
        type: 'label_select',
        values: CARD_RATING,
      },

      // Дополнительно
      se: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'accommodation.attr_type.se.name'},
        values: CARD_ACCOMMODATION_SE,
      },
    },
  };
}

export function getPublicPlaceFilterConfig() {
  return {
    filters: {
      category: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'public_place.attr_type.ctg.name'},
        values: CARD_PUBLIC_PLACE_CTG,
      },
    },
  };
}

export function getActivityFilterConfig() {
  return {
    filters: {
      category: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'activity.attr_type.ctg.name'},
        values: CARD_ACTIVITY_CTG,
      },

      // Рейтинг
      rat: {
        title: {ns: 'api_cat', t: 'rat.name'},
        type: 'label_select',
        values: CARD_RATING,
      },

      additional_filters: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'activity.attr_type.additional_filters.name'},
        values: CARD_ACTIVITY_FILTERS,
      },
      group_type: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'activity.attr_type.group_type.name'},
        values: CARD_ACTIVITY_GROUP_TYPE,
      },
      housing: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'activity.attr_type.housing_type.name'},
        values: CARD_ACTIVITY_HOUSING,
      },
      lang: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'activity.attr_type.lang.name'},
        values: CARD_ACTIVITY_LANG,
      },
      schedule_type: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'activity.attr_type.schedule_type.name'},
        values: CARD_ACTIVITY_SCHEDULE_TYPE,
      },
      transfer: {
        type: 'multi_select',
        title: {ns: 'api_cat', t: 'activity.attr_type.transfer_type.name'},
        values: CARD_ACTIVITY_TRANSFER,
      },
    },
  };
}

export function getFilterConfig(typeId: string, options: Record<string, any>) {
  switch (typeId) {
    case '1':
      return getRestaurantFilterConfig();
    case '2':
      return getAttractionFilterConfig();
    case '3':
      return getAccommodationFilterConfig();
    case '4':
      return getPublicPlaceFilterConfig();
    case '5':
      return getActivityFilterConfig();
    default:
      throw new Error('Unknown type');
  }
}