import { ICurrency } from '@main/src/types/common';

export enum ETagsTypes {
	transport = 'transport',
	person = 'person',
	stars = 'stars',
}
export enum EDescriptionTag {
	duration,
	scheduleType,
	creator,
	attractionStyles,
	firstPerson,
}
export interface IDescriptionTag {
	type: EDescriptionTag;
	value: string | string[];
}
export interface ITag {
	id: string;
	name: string;
	type: ETagsTypes;
	group?: string;
}
export interface IPropsTags {
	tags: ITag[];
	marginBottom?: string;
}
