export const DATA = {
	ru: {
		data: {
			page: {
				data: {
					faq: {
						title: 'Часто задаваемые <span>вопросы</span>',
						questions: [
							{
								question: '1. В каких направлениях туризма специализируется QVEDO?',
								response:
									'В настоящий момент QVEDO специализируется на букинге отелей и гостиниц, экскурсионном и активном туризме, пакетных турах, морских круизах и ресторанном бизнесе.',
							},
							{
								question: '2. В каких странах и регионах планирует присутствовать QVEDO?',
								response:
									'Мы стартуем с Российском Федерации, и продолжаем свое развитие в таких регионах как: Азия, Европа, Латинская Америка и Африка.',
							},
							{
								question: '3. На каких платформах присутствует QVEDO?',
								response:
									'QVEDO - это тревел суперапп, который можно найти в RuStore, Google Play, App Store, HUAWEI, AppGallery, а также доступна Web версия сайта. Регистрируясь у нас, вы присутствуете везде.',
							},
							{
								question: '4. Остались еще вопросы?',
								response: 'Напишите на <a href="mailto:support@qvedo.com">support@qvedo.com</a>',
							},
						],
						description:
							'Мы постоянно вовлечены и поддерживаем коммуникацию 24/7 с поставщиками и с пользователями услуг',
					},
					company: {
						title: 'Все впечатления и возможности мира <span>доступны в QVEDO!</span>',
						mission: {
							title: 'Наша миссия:',
							description:
								'&nbsp&nbsp&nbspМы живем ради впечатлений. Они наполняют нашу привычную жизнь  радостью и ощущением момента.<br/><br/>&nbsp&nbsp&nbspИменно поэтому мы помогаем людям проживать больше впечатлений каждый день.<br/><br/>&nbsp&nbsp&nbspНе важно, речь о кругосветке или кофе-брейке. Каждое подобранное впечатление уникально и неповторимо.',
						},
						description:
							'QVEDO - это туристическое приложение, в котором можно спланировать любое путешествие. Теперь не нужно переключаться между десятками приложений по бронированию отелей, поиску экскурсий, достопримечательностей и ресторанов - все это и многое другое реализовано в QVEDO superapp.',
					},
					benefits: {
						items: [
							{
								title: 'Охват и покрытие',
								description: 'Размещаем услуги по всей России, Азии, Латинской Америке и Европе',
							},
							{
								title: 'Удобный интерфейс',
								description: 'Наше приложение включает самые полезные туристические сервисы доступные в трех кликах',
							},
							{
								title: 'Умная система фильтров',
								description: 'Вы сможете подобрать впечатление на любой вкус и формат',
							},
							{
								title: 'Персональные рекомендации',
								description: 'QVEDO сам подберет интересные впечатления ориентируясь на ваши предпочтения',
							},
						],
						title: 'Наши <span>преимущества</span>',
					},
					contacts: {
						items: {
							vk: 'https://vk.com/qvedo',
							email: 'hello@qvedo.com',
							telegram: 'https://t.me/qvedo',
						},
						title: 'Контакты',
						address:
							'ООО КВЕДО ИНН 5009127836 КПП 500901001 ОГРН 1215000057810 142002, МОСКОВСКАЯ ОБЛ., ДОМОДЕДОВО Г.О., ДОМОДЕДОВО Г., МКР. ЗАПАДНЫЙ, ТЕКСТИЛЬЩИКОВ УЛ., Д. 41Б, ПОМЕЩ. 6',
						cooperation: {
							items: {
								B2G: { email: 'region@qvedo.com', title: 'Для В2G' },
								clients: { email: ['b2b@qvedo.com'], title: 'Для Поставщиков' },
								business: { email: 'partners@qvedo.com', title: 'Бизнес предложения' },
								marketing: { email: 'marketing@qvedo.com', title: 'По рекламе и маркетингу' },
							},
							title: 'Связаться с нами',
						},
						description: 'Связаться с нами можно несколькими способами. Выберите наиболее подходящий вариант.',
					},
					services: {
						items: [
							'Бронирование<br/><span>отелей и гостиниц</span>',
							'Поиск и бронирование<br/> <span>экскурсий и активностей</span>',
							'Поиск <span>ресторанов и<br/> достопримечательностей</span>',
							'Поиск <span>туров и<br/> билетов</span>',
						],
						title: 'Какие <span>сервисы</span> реализованы в QVEDO?',
						description:
							'Мы работаем со всеми туристическими направлениями в разных городах России и насчитываем более 100 000 активностей.',
					},
					howItWorks: {
						items: [
							{
								title: '<span>1. Бронирование</span>',
								description: 'Достаточно трех простых шагов для подбора, ознакомления и бронирования активности',
							},
							{
								title: '<span>2. Подтверждение</span>',
								description:
									'Один простой шаг Поставщика -  это подтверждение  бронирования, остальное мы берем на себя',
							},
							{
								title: '<span>3. Online Оплата</span>',
								description: 'Быстрая и безопасная online оплата с поддержкой платежных систем МИР, VISA и MASTERCARD',
							},
						],
						title: 'Насколько <span>легко</span> это работает',
						description: 'Всего три простых шага для совершения транзакции:',
					},
				},
				createdAt: '2022-10-14T16:32:32.533Z',
				updatedAt: '2022-10-28T15:39:25.086Z',
				publishedAt: '2022-10-14T16:32:33.983Z',
				locale: 'ru',
			},
		},
	},

	en: {
		data: {
			page: {
				data: {
					faq: {
						title: 'Frequently Asked <span>questions</span>',
						questions: [
							{
								question: '1. In which areas of tourism does QVEDO specialize?',
								response:
									'Currently, QVEDO specializes in booking hotels and hotels, sightseeing and active tourism, package tours, sea cruises and the restaurant business.',
							},
							{
								question: '2. In which countries and regions does QVEDO plan to be present?',
								response:
									'We start with the Russian Federation, and continue our development in such regions as Asia, Europe, Latin America and Africa.',
							},
							{
								question: '3. On which platforms is QVEDO present?',
								response:
									'QVEDO is a travel superapp that can be found in RuStore, Google Play, App Store, HUAWEI, AppGallery, and a Web version of the site is also available. By registering with us, you are present everywhere.',
							},
							{
								question: '4. Any more questions?',
								response: 'Write to <a href="mailto:support@qvedo.com ">support@qvedo.com </a>',
							},
						],
						description: 'We are constantly involved and maintain 24/7 communication with service providers and users',
					},
					company: {
						title: 'All the experiences and opportunities of the <span> world are available in QVEDO!</span>',
						mission: {
							title: 'Our mission:',
							description:
								"&nbsp&nbsp&nbsp We live for the sake of impressions. They fill our habitual life with joy and a sense of the moment.<br/><br/> That's why we help people live more experiences every day.<br/><br/>&nbsp&nbsp&nbsp It doesn't matter if it's about a round-the-world trip or a coffee break. Each selected impression is unique and inimitable.",
						},
						description:
							'QVEDO is a travel app where you can plan any trip. Now there is no need to switch between dozens of applications for booking hotels, searching for excursions, attractions and restaurants - all this and much more is implemented in QVEDO superapp.',
					},
					benefits: {
						items: [
							{
								title: 'Coverage and coverage',
								description: 'We place services throughout Russia, Asia, Latin America and Europe',
							},
							{
								title: 'User-friendly interface',
								description: 'Our app includes the most useful travel services available in three clicks',
							},
							{
								title: 'Smart Filter System',
								description: 'You will be able to choose an impression for every taste and format',
							},
							{
								title: 'Personal recommendations',
								description: 'QVEDO will pick up interesting impressions based on your preferences',
							},
						],
						title: 'Our <span>advantages</span>',
					},
					contacts: {
						items: {
							vk: 'https://vk.com/qvedo',
							email: 'hello@qvedo.com',
							telegram: 'https://t.me/qvedo',
						},
						title: 'Contacts',
						address:
							'LLC KVEDO INN 5009127836 KPP 500901001 OGRN 1215000057810 142002, MOSCOW REGION, DOMODEDOVO G.O., DOMODEDOVO G., MKR. ZAPADNY, TEKSTILSHCHIKOV STR., 41B, ROOM 6',
						cooperation: {
							items: {
								B2G: { email: 'region@qvedo.com ', title: 'For B2G' },
								clients: { email: ['b2b@qvedo.com'], title: 'For Suppliers' },
								business: { email: 'partners@qvedo.com ', title: 'Business offers' },
								marketing: { email: 'marketing@qvedo.com ', title: 'On advertising and Marketing' },
							},
							title: 'Contact us',
						},
						description: 'There are several ways to contact us. Choose the most suitable option.',
					},
					services: {
						items: [
							'Booking<br/><span>hotels and inns</span>',
							'Search and booking<br/> <span>excursions and activities</span>',
							'Search for <span>restaurants and<br/> attractions</span>',
							'Search for <span>tours and<br/> tickets</span>',
						],
						title: 'What <span>services</span> are implemented in QVEDO?',
						description:
							'We work with all tourist destinations in different cities of Russia and have more than 100,000 activities.',
					},
					howItWorks: {
						items: [
							{
								title: '<span>1. Booking</span>',
								description: 'Three simple steps are enough to select, review and book an activity',
							},
							{
								title: '<span>2. Confirmation</span>',
								description: 'One simple step of the Supplier is to confirm the booking, we take care of the rest',
							},
							{
								title: '<span>3. Online Payment</span>',
								description: 'Fast and secure online payment with support of MIR, VISA and MASTERCARD payment systems',
							},
						],
						title: 'How <span>easy</span> does it work',
						description: 'Just three simple steps to complete a transaction:',
					},
				},
				createdAt: '2022-10-14T16:32:32.533Z',
				updatedAt: '2022-10-28T15:39:25.086Z',
				publishedAt: '2022-10-14T16:32:33.983Z',
				locale: 'ru',
			},
		},
	},
};
