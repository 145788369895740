export enum ERateValue {
	one = 1,
	two = 2,
	three = 3,
	four = 4,
	five = 5,
}

export interface IReviewsGroup {
	rate: ERateValue;
	reviewsCount: number;
}

export enum ERateType {
	Parameter1 = 'Parameter1',
	Parameter2 = 'Parameter2',
	Parameter3 = 'Parameter3',
	Parameter4 = 'Parameter4',
	Parameter5 = 'Parameter5',
}

export interface IReviewsParameter {
	type: ERateType;
	rate: number;
}

export enum EActivityProviderContact {
	phone = 'phone',
	email = 'email',
}

export interface IActivityProviderContact {
	type: EActivityProviderContact;
	value: string;
}
