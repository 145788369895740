/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { makeComponentName } from './componentName';

interface IMakeContextModuleOptions<T> {
	contextName: string;
	defaultValue?: T;
}

type TMapContextToProps<CP, T> = (context: T) => CP;
type TMergeProps<P, CP> = (ownProps: Omit<P, keyof CP>, contextProps: CP) => P;

export function makeContext<T>(options: IMakeContextModuleOptions<T>) {
	const { defaultValue = null, contextName } = options;

	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	const Context = React.createContext<T>(defaultValue as any);
	Context.displayName = contextName;

	const useContext = () => {
		const context = React.useContext(Context);

		if (!context) {
			throw new Error(`used ${contextName} outside of context`);
		}

		return context;
	};

	const withContext = function <CP, P extends CP>(
		mapContextToProps: TMapContextToProps<CP, T>,
		mergeProps?: TMergeProps<P, CP>
	) {
		return (Component: React.ComponentType<P>) => {
			const componentName = makeComponentName(Component, `with${contextName}`);

			class WithContext extends React.Component<P & { context: T }> {
				public static displayName = componentName;
				public static contextType = Context;

				public render() {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const { context } = this;

					if (!context) {
						throw new Error(`used ${contextName} outside of context`);
					}

					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//  @ts-ignore
					const contextProps = mapContextToProps(context);
					const resultProps = (mergeProps && mergeProps(this.props, contextProps)) || {
						...contextProps,
						...this.props,
					};

					return <Component {...resultProps} />;
				}
			}

			return WithContext;
		};
	};

	return {
		Context,
		useContext,
		withContext,
	};
}
