import { IOrder } from 'src/types/orders';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { IFetchOrderParams, IFetchOrderResponse, IFetchOrderServiceParams, IFetchOrderServiceRequest } from './types';
import { mapper } from './mapper';
import { captureException } from '@sentry/core';
import { fetchCurrencyRates } from '@app/api/services/node/currencyRates';

export const fetchOrder = async ({
	orderId,
	currency,
	currencyRates,
}: IFetchOrderServiceParams): Promise<IOrder | undefined> => {
	const api = await getBrowserHttpClient();
	try {
		const currencyRates = await fetchCurrencyRates();
		const result = await api.post<OrderUsrReadResp, IFetchOrderServiceRequest>(
			'/client/order/read',
			{
				order_id: orderId,
			},
			{
				headers: {},
			}
		);

		return mapper({
			response: result,
			currency,
			currencyRates,
		});
	} catch (error) {
		captureException(error);

		return undefined;
	}
};
