import axios from 'axios';
import { IOrderCreateResponse, IOrderCreateParams, IOrderCreateRequest } from './types';
import { fetchEngageSave } from '@app/api';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { captureException } from '@sentry/core';

export const fetchOrderCreate = async ({
	lang,
	tickets,
	cardId,
	date,
}: IOrderCreateParams): Promise<IOrderCreateResponse | undefined> => {
	const api = await getBrowserHttpClient();
	try {
		const engageSaveResult = await fetchEngageSave({
			lang,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			card_id: cardId,
			tickets,
			tickets_date: date * 1000, //timestamp * 1000 = date time ms
		});

		if (engageSaveResult) {
			const response = await api.post<{ data: IOrderCreateResponse }, IOrderCreateRequest>(
				'/client/order/create',
				{
					card_id: cardId,
				},
				{
					headers: {},
				}
			);

			return response.data;
		}

		return undefined;
	} catch (error) {
		captureException(error);

		return undefined;
	}
};
