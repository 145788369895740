import { IPrice } from './price';
import { ERateValue } from './rate';
import { ITag } from '@main/src/types/activity';
import { ITicketsCustom } from '@main/src/types/map/searchArea';

export enum EAnswerType {
	provider = 'provider',
	client = 'client',
}

export enum EActivityType {
	'restaurant' = 'restaurant',
	'attraction' = 'attraction',
	'activity' = 'activity',
	'accommodation' = 'accommodation',
	'publicPlace' = 'public_place',
}
export interface IActivityCard {
	id: string;
	title: string;
	price: IPrice;
	type: EActivityType;
	image: string | null;
	rate?: ERateValue;
	slug?: string;
	newWindow?: boolean;
	mini?: boolean;
	isPoint?: boolean;
	priceLevel: string | null;
	rating: number | null;
	openNow: boolean;
	closed: string | null;
	description: string | null;
	city: string | null;
	images?: string[];
	distance?: number;
	tags?: ITag[];
	hotelRates: RoomsCardRate | null;
	tickets?: ITicketsCustom;
	requestId?: string;
}

export interface IActivityCardCollection extends IActivityCard {
	description: string | null;
	location?: {
		name: string;
		coordinates: [number, number];
	};
}

export interface IActivitySchedule {
	id: number;
	sort: number;
	title: string;
	description: string;
	timeStart: Date;
	timeEnd: Date;
}

export interface IActivityDetail {
	id: number;
	title: string;
	description: string;
	schedule: IActivitySchedule[];
}
