import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { IFetchOrderCancelParams, IFetchOrderCancelResponse } from './types';
import { captureException } from '@sentry/core';

export const fetchOrderCancel = async (
	orderId: string,
	cancelReason: string
): Promise<IFetchOrderCancelResponse | boolean> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<{ data: IFetchOrderCancelResponse }, IFetchOrderCancelParams>(
			'/client/order/cancel',
			{
				order_id: orderId,
				cancel_reason: cancelReason,
			},
			{
				headers: {},
			}
		);

		return true;
	} catch (error) {
		captureException(error);

		return false;
	}
};
