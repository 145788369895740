import { IActivityOfferV1, IMapperParams, IOpenTime, IResponseData } from '@app/api/services/node/cardAll/types';
import { ICardDetail } from '@main/src/types/pages/cardDetail';
import { ETagsTypes } from '@main/src/types/activity';
import { get, set } from 'lodash';
import { getCurrency } from '@common/helpers';
import { ICurrency } from '@main/src/types/common';
import { ICurrencyRates } from '@main/src/types/common/currencyRates';
import { USD_CURRENCY } from '@app/constants/currencies';

export type TTextFromServer = Record<string, string | boolean | undefined | unknown>;
export type TTextClient = Record<string, string | string[] | boolean | boolean[] | undefined | unknown>;

export const parseTexts = (texts: TTextFromServer): TTextClient => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return Object.keys(texts).reduce((acc, prop) => {
		set(acc, prop, get(texts, prop));

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return acc;
	}, {} as any);
};

const setTextsItem = (obj: any, texts: any, item: string, prefix?: string) => {
	const fullPath = `${prefix ? prefix : ''}${item}`;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const value = get(obj, fullPath);
	if (typeof value === 'string') {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		texts[fullPath] = value;
	} else if (Array.isArray(value)) {
		value.forEach((arrayItem: any, index: number) => {
			setTextsItem(obj, texts, index.toString(), `${prefix ? prefix : ''}${item}.`);
		});
	} else {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		Object.keys(value).forEach(item => {
			setTextsItem(obj, texts, item, `${fullPath}.`);
		});
	}
};

export const convertToTexts = (obj: any) => {
	const texts: any = {};
	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	Object.keys(obj).forEach(item => {
		setTextsItem(obj, texts, item);
	});

	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return texts;
};

export const prepareTags = (type: string, tags: string[]) => {
	return tags.map(item => {
		const subStr = item.substring(0, item.indexOf('.'));

		return {
			name: `cat.${type}.${item}.name`,
			id: item,
			type: ETagsTypes.person,
			group: subStr ? `cat.${type}.attr_type.${subStr}.name` : '',
		};
	});
};

export const textsGetText = (
	key: string,
	req: TTextClient,
	src: TTextClient,
	auto: TTextClient
): string | undefined => {
	const textReq = req[key];
	const textSrc = src[key];
	const textAuto = auto[key];

	if (!!textReq && typeof textReq === 'string') {
		if (!!textAuto) {
			return `* ${textReq}`;
		} else {
			return textReq;
		}
	} else if (!!textSrc && typeof textSrc === 'string') {
		return textSrc;
	}

	return undefined;
};

export function textsGetArray<T>(key: string, req: TTextClient, src: TTextClient): T[] | undefined {
	const textReq = req[key];
	const textSrc = src[key];

	if (Array.isArray(textReq)) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return textReq;
	} else if (Array.isArray(textSrc)) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return textSrc;
	}

	return undefined;
}

export const mapperCardAll = ({ data, currency, currencyRates, locale }: IMapperParams): ICardDetail | null => {
	if (data.data) {
		const tags = [...(data.data?.card.card_tags || []), ...(data.data?.data.c.more_tags || [])];
		const preparedImages = data.data.images.map(i => ({
			thumbnail: `https://img.qvedo.com/images/${i}/Sx3`,
			original: `https://img.qvedo.com/images/${i}/Vx2`,
		}));

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const textsReq = parseTexts(data.data.texts?.req || {});
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const textsSrc = parseTexts(data.data.texts?.src || {});
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const textsAuto = parseTexts(data.data.texts?.auto || {});

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const daysById = (textsReq?.daysById || {}) as any;

		const days = (data.data.data as IActivityOfferV1)?.c?.days?.map(day => ({
			images_ids: day.images_ids || [],
			housing_type: day.housing_type && `ui:activity.housing_type.${day.housing_type}.name`,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
			title: daysById[day.id]?.title || '',
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
			description: daysById[day.id]?.description || '',
		}));

		return {
			entry: data.data.title.req || data.data.title.src,
			hasTitleAuto: data.data.title.auto,
			tags: prepareTags(data.data?.card.card_type, tags),
			center: data.data.lat_lng,
			contacts: {
				web: data.data.data.c.contacts.web || [],
				email: data.data.data.c.contacts.email || [],
				phone: data.data.data.c.contacts.phone || [],
				fax: data.data.data.c.contacts.fax || [],
				social: [],
				socialStrings: data.data.data.c.contacts.social || [],
				more: data.data.data.c.more?.social_links?.map(i => i.url) || [],
			},
			nameLocation: {
				name: data.data.title.req || data.data.title.src || '',
				locationName: data.data.address?.formatted || '',
				address: data.data.address?.display.slice(0, data.data.address?.display.length - 1).join(', ') || '',
				city: data.data.address?.city_name || '',
				rate: data.data.card.rating || 0,
				reviewCount: 0,
			},
			imagesGallery: preparedImages,
			details: {
				text:
					textsGetText('description', textsReq, textsSrc, textsAuto) ||
					textsGetText('short', textsReq, textsSrc, textsAuto) ||
					'',
				startPointDescription: textsGetText('startPointDescription', textsReq, textsSrc, textsAuto) || null,
				finishPointDescription: textsGetText('finishPointDescription', textsReq, textsSrc, textsAuto) || null,
				cancelationRules: textsGetText('cancelationRules', textsReq, textsSrc, textsAuto) || null,
				howToGetToUs: textsGetText('howToGetToUs', textsReq, textsSrc, textsAuto) || null,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				duration: (data.data.data as IActivityOfferV1)?.c?.duration || null,
				hasTextAuto: !!textsAuto.description || !!textsAuto.short || false,
				hasStartPointDescriptionAuto: !!textsAuto.startPointDescription || false,
				hasFinishPointDescriptionAuto: !!textsAuto.finishPointDescription || false,
				hasCancelationRulesAuto: !!textsAuto.cancelationRules || false,
				hasWowToGetToUsAuto: !!textsAuto.howToGetToUs || false,
				activityRestrictions: textsGetArray<string>('activityRestrictions', textsReq, textsSrc) || [],
				hasActivityRestrictions: (textsAuto['activityRestrictions'] as boolean[]) || [],
				participationRequirement: textsGetArray<string>('participationRequirement', textsReq, textsSrc) || [],
				hasParticipationRequirement: (textsAuto['participationRequirement'] as boolean[]) || [],
				recomendedToTake: textsGetArray<string>('recomendedToTake', textsReq, textsSrc) || [],
				hasRecomendedToTake: (textsAuto['recomendedToTake'] as boolean[]) || [],
				price: data.data.card.price,
				priceLevel: null, //data.data.card.price_level,
			},
			offers: [],
			parameters: {
				departure: '',
				departureTime: '',
				destination: '',
				excluded: textsGetArray<string>('whatsExcluded', textsReq, textsSrc) || [],
				included: textsGetArray<string>('whatsIncluded', textsReq, textsSrc) || [],
				returnTime: '',
			},
			provider: {
				id: data.data.supplier?.id || '',
				description: data.data.supplier?.description || '',
				rating: data.data.card.rating || -1,
				name: data.data.supplier?.displayName || '',
				contacts: [],
				avatarUrl: (data.data.supplier?.cover && `https://img.qvedo.com/avatars/${data.data.supplier.cover}/Sx3`) || '',
			},
			type: data.data.card.card_type,
			originalType: data.data.card.card_type as GlobMapOrderCardType,
			reviews: [],
			supplierTeam: [],
			work_time: null,
			// TODO move to work_time
			newWorkTime: data.data.data.c.open_time ? convertDeepNumbersToTime(data.data.data.c.open_time) : [],

			tickets: data.data.tickets.map(ticket => {
				let originalCurrency = null;
				let originalCurrencyPrice = null;
				let userCurrencyPrice = null;

				if (currencyRates) {
					if (ticket.data?.c?.currency) {
						originalCurrency = getCurrency(ticket.data.c.currency);

						if (originalCurrency?.code !== ticket.data.c.currency) {
							originalCurrencyPrice =
								ticket.data?.c?.price * currencyRates[ticket.data.c.currency][originalCurrency.code];
						}
					}
					if (
						currencyRates &&
						currency &&
						originalCurrency &&
						ticket.data?.c?.price &&
						currency.code !== originalCurrency?.code
					) {
						const currentPrice = originalCurrencyPrice || ticket.data.c.price;
						userCurrencyPrice = currentPrice * currencyRates[originalCurrency?.code][currency.code];
					}
				}

				return {
					lang: locale,
					ticketId: ticket.ticket_id,
					title: ticket.texts?.req.title || ticket.texts?.src.title || '',
					description: ticket.texts?.req.description || ticket.texts?.src.description || '',
					currency: originalCurrency,
					price: originalCurrencyPrice || ticket.data?.c?.price || -1,
					userCurrency: currency,
					userPrice: userCurrencyPrice,
					imageId: ticket.data?.c?.image_id ? `https://img.qvedo.com/images/${ticket.data?.c?.image_id}/Mx2` : null,
				};
			}),
			days: days || null,
			activityTimeSlots: [],
			de: 'dev',
		};
	}

	return null;
};

export function numberToTime(item: number): string {
	const lengthItem = String(item).length;
	const nullsString = new Array(5 - lengthItem).join('0');

	const array = `${nullsString}${item}`.split('');

	return `${array[0]}${array[1]}:${array[2]}${array[3]}`;
}

const convertDeepNumbersToTime = (time: IOpenTime): Array<Array<[string, string] | []>> | null => {
	if (time.type === 'all_time') return null;

	if (time?.week_time) {
		return time?.week_time.map(items => {
			return items.map(item => {
				if (item.length === 1) {
					const beginTime = item[0];

					return [numberToTime(beginTime), numberToTime(2359)];
				} else if (item.length === 2) {
					const beginTime = item[0];
					const endTime = item[1];

					return [numberToTime(beginTime), numberToTime(endTime)];
				}

				return [];
			});
		});
	}

	return null;
};
