import { getAvatarUrlById } from '@common/helpers';
import type { IUserResponse } from '@main/src/types/apiCustom';
import type { IUser } from '@main/src/types/common';

export const userMapper = (info: IUserResponse): IUser => {
	return {
		id: info.id,
		firstName: info.profile.first_name,
		lastName: info.profile.last_name,
		email: '',
		gender: info.profile.gender || 'NOT_SET',
		avatar: {
			id: info.profile.avatar.id,
			url: getAvatarUrlById(info.profile.avatar.id),
		},
		birthDate: info.profile.birth_date || null,
		phoneNumber: info.profile.phone_number,
		citizenship: info.profile.citizenship,
	};
};
