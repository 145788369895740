import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComponentName(Component: React.ComponentType<any>): string {
	return Component.displayName || Component.name || 'Component';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeComponentName(Component: React.ComponentType<any>, hoc: string): string {
	return `${hoc}(${getComponentName(Component)})`;
}
