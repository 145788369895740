import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { AuthContext, IAuthContext } from './context';
import * as React from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { useRouter } from 'next/router';
import { IAuthStorage } from './types';
import { authServiceBrowser } from '@app/api/browserHttpApi';
import { DEFAULT_LOCALE } from '@app/constants/locales';

export interface IAuthContextWrapper {
	children: ReactNode;
	hasAnonymous: boolean;
}
export const AuthContextWrapper: FC<IAuthContextWrapper> = ({ children, hasAnonymous }: IAuthContextWrapper) => {
	const { locale } = useRouter();
	const [authStorage, setAuthStorage] = useLocalStorage<IAuthStorage>('qvd/auth', {
		hasAnonymous,
	});
	const login = async (params: Auth2WebLoginReq): Promise<Auth2WebTokenResp> => {
		const resultLogin = await authServiceBrowser.login(params);

		setAuthStorage({
			hasAnonymous: resultLogin.ano,
		});

		return resultLogin;
	};

	const sendPasswordlessCode = async (requestParams: Auth2WebOnetimeEmailReq): Promise<void> => {
		await authServiceBrowser.sendPasswordlessCode(requestParams);
	};

	const logout = async () => {
		setAuthStorage({
			hasAnonymous: true,
		});
		await login({
			auth_type: 'device',
			lang: (locale as LangCodeForUI) || DEFAULT_LOCALE,
		});
	};

	const getHasAnonymous = useCallback(() => {
		return authStorage.hasAnonymous;
	}, [authStorage]);

	const [clientWidth, setViewWidth] = useState<number | undefined>();

	const context = React.useMemo(
		() => ({
			login,
			logout,
			getHasAnonymous,
			sendPasswordlessCode,
			clientWidth,
		}),
		[login, logout, getHasAnonymous]
	);

	const onFocus = () => {
		setAuthStorage({
			hasAnonymous: authServiceBrowser.hasAnonymous(),
		});
	};

	const getViewWidth = useCallback(() => {
		return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	}, []);

	const handlerResizeWindow = () => {
		setViewWidth(getViewWidth());
	};

	useEffect(() => {
		handlerResizeWindow();
		window.addEventListener('focus', onFocus);
		window.addEventListener('resize', handlerResizeWindow);
		onFocus();
		return () => {
			window.removeEventListener('focus', onFocus);
			window.removeEventListener('resize', handlerResizeWindow);
		};
	}, []);

	return <AuthContext.Provider value={context as IAuthContext}>{children}</AuthContext.Provider>;
};

AuthContextWrapper.displayName = 'AuthContextWrapper';
