import { IconActivity, IconCorporateFare, IconKingBad, IconLunchBurger, IconTour } from '@main/src/new/old/Icons';
import { EActivityType } from '@main/src/types/entities/activity';

export const getNameType = (type: EActivityType): { icon: JSX.Element } | null => {
	switch (type) {
		case 'restaurant':
			return { icon: <IconLunchBurger /> };
		case 'attraction':
			return { icon: <IconTour /> };
		case 'activity':
			return { icon: <IconActivity /> };
		case 'accommodation':
			return { icon: <IconKingBad /> };
		case 'public_place':
			return { icon: <IconCorporateFare /> };

		default:
			return null;
	}
};

export const getUrlType = (type: EActivityType): string | null => {
	switch (type) {
		case 'restaurant':
			return 'restaurant';
		case 'attraction':
			return 'attraction';
		case 'activity':
			return 'activity';
		case 'accommodation':
			return 'hotel';
		case 'public_place':
			return 'public_place';

		default:
			return null;
	}
};
