import { PageResponse } from '@app/api/types';
import { IAboutB2CProps } from '@main/src/types/pages/about';
import { DATA } from './constants';
import { captureException } from '@sentry/core';

export const fetchAboutB2CPage = async (lang: string): Promise<IAboutB2CProps | null> => {
	try {
		// const result = await nodeHttpApi.apiget<{ data: { page: PageResponse & { data: IAboutB2CProps } } }>('/pages/about-b2c', {
		// 	lang,
		// });

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (!DATA[lang]) {
			return null;
		}

		const result: { data: { page: PageResponse & { data: IAboutB2CProps } } } = await new Promise(resolve => {
			setTimeout(function () {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				resolve(DATA[lang] as { data: { page: PageResponse & { data: IAboutB2CProps } } });
			}, 0);
		});

		return result.data.page.data;
	} catch (error) {
		captureException(error);

		return null;
	}
};
