import { makeContext } from '@common/utils/makeContext';

export interface IAuthContext {
	login(params: Auth2WebLoginReq): Promise<Auth2WebTokenResp>;
	logout(): Promise<void>;
	sendPasswordlessCode(requestParams: Auth2WebOnetimeEmailReq): Promise<void>;
	getHasAnonymous(): boolean;
	clientWidth?: number;
}

export const {
	Context: AuthContext,
	useContext: useAuthContext,
	withContext: withAuthContext,
} = makeContext<IAuthContext>({
	contextName: 'AuthContext',
});
