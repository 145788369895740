import { ICurrency } from '@main/src/types/common';
import { parsePrice } from '@common/helpers/components';

type ApproximatePriceFunction = (
	currency?: ICurrency | null,
	value?: number | null | undefined,
	showBrackets?: boolean
) => string | null;

export const getApproximatePrice: ApproximatePriceFunction = (currency, value, showBrackets = true): string => {
	if (typeof value === 'number' && currency?.symbol) {
		let priceValue = parsePrice({ currency, value });
		const brackets = showBrackets ? ['(', ')'] : ['', ''];
		let symbol = '~';
		if (value > 0 && value <= 1) {
			symbol = '<';
			priceValue = parsePrice({ currency, value: 1 });
		}

		return `${brackets[0]}${symbol} ${priceValue}${brackets[1]}`;
	}

	return '';
};
