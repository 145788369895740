import { EOrderStatus, IMapperParams } from '@app/api/services/shared/orders/types';
import { TOrderCard } from '@main/src/types/orders';
import { getCurrency } from '@common/helpers';
import { EActivityType } from '@main/src/types/entities/activity';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const statusMapper = (status: string): EOrderStatus => {
	switch (status) {
		case 'wait_pay':
			return EOrderStatus.wait_pay;
		case 'cancel_s':
			return EOrderStatus.cancel_s;
		case 'created':
			return EOrderStatus.created;
		case 'cancel_u':
			return EOrderStatus.cancel_u;
		case 'payment':
			return EOrderStatus.payment;
		case 'processing':
			return EOrderStatus.processing;
		case 'failed':
			return EOrderStatus.failed;

		default:
			return EOrderStatus.expired;
	}
};

export const changeFirstLetter = (str: string) => {
	return str[0].toUpperCase() + str.slice(1);
};

export const mapper = ({ orders, currency, currencyRates }: IMapperParams): TOrderCard[] => {
	return orders
		.sort((a, b) => b.order_ts - a.order_ts)
		.map(order => {
			const status = statusMapper(order.order_status);
			const originalCurrency = getCurrency(order.order_details.currency);
			let userCurrencyPrice = null;
			if (
				status !== EOrderStatus.payment &&
				currencyRates &&
				currency &&
				currency.code !== order.order_details.currency
			) {
				// TODO NHL currency not found
				if (currencyRates[order.order_details.currency]) {
					userCurrencyPrice =
						order.order_details.order_sum * currencyRates[order.order_details.currency][currency?.code];
				}
			}

			let imageUrl;

			if (order.order_details.offer.offer_image) {
				if (order.order_details.ext_src === 'etg') {
					imageUrl = order.order_details.offer.offer_image.replace('{size}', '1024x768');
				} else {
					imageUrl = `https://img.qvedo.com/images/${order.order_details.offer.offer_image}/Sx3`;
				}
			}

			return {
				type: order.order_details.ext_src === 'etg' ? EActivityType.accommodation : EActivityType.activity,
				id: order.order_id,
				number: order.order_number,
				title: order.order_details.offer.offer_title,
				dialogId: order.order_dialog,
				currency: originalCurrency,
				price: order.order_details.order_sum,
				userCurrency: currency,
				userPrice: userCurrencyPrice,
				countTickets: order.order_details.tickets.reduce((prev, current) => {
					return prev + current.ticket_number;
				}, 0),
				date: changeFirstLetter(dayjs(order.order_ts).utc(false).format('DD.MM.YYYY')),
				bookingDate: dayjs(order.order_details.tickets_date).utc(false).format('DD.MM.YYYY'),
				status,
				// TODO copy enum from back types to app types
				statusOriginal: order.order_status,
				imageUrl,
				newMsg: 0,
			};
		});
};
