import { IPrice } from 'src/types/entities/price';
import { numberFormat } from '@common/helpers/numberFormat';

export const parsePrice = (price: IPrice): string => {
	if (typeof price.value === 'number' && price?.currency?.symbol) {
		let priceValue = numberFormat(Number(Number(price.value).toFixed(2)));
		if (price.value > 0 && price.value < 1) {
			priceValue = Number(priceValue).toFixed(2);
		}
		if (price.currency.prefix) {
			return `${price.currency.symbol}${priceValue}`;
		}
		return `${priceValue}${price.currency.symbol}`;
	}

	return '';
};
