import axios from 'axios';
import { IParams, IRequestParams } from './types';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { captureException } from '@sentry/core';

export const fetchEngageSave = async ({ lang, card_id, tickets, tickets_date }: IParams): Promise<boolean> => {
	const api = await getBrowserHttpClient();
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const response: any = await api.post<any, IRequestParams>(
			'/client/order/engage/save',
			{
				lang,
				card_id,
				tickets,
				tickets_date,
			},
			{
				headers: {},
			}
		);

		// console.log('response fetchEngageSave', response);

		return true;
	} catch (error) {
		captureException(error);

		return false;
	}
};
