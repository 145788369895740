import { ICardResultV2 } from '@main/src/types/map/searchArea';
import { IResponseData } from '@app/api/services/shared/cardsShort/types';
import { EActivityType } from '@main/src/types/entities/activity';
import { getCurrency } from '@common/helpers';
import { ICurrencyRates } from '@main/src/types/common/currencyRates';
import { ICurrency } from '@main/src/types/common';

export const mapperCardsShort = (
	data: IResponseData,
	currencyRates?: ICurrencyRates | null,
	currency?: ICurrency | null
): ICardResultV2[] => {
	return data.data.map((item): ICardResultV2 => {
		const { card_id, card, address, title, texts, images, city_name } = item;
		const preparedImages = images.map(image => `https://img.qvedo.com/images/${image}/Mx2`);
		const originalCurrency = getCurrency(card.currency);
		const preparedTitle = title?.req || title?.src;
		const preparedDescription = texts.req.short || texts.src.short || '';

		let userCurrencyPrice = null;
		if (currencyRates && currency && originalCurrency && card?.price && currency.code !== originalCurrency?.code) {
			userCurrencyPrice = card.price * currencyRates[originalCurrency?.code][currency.code];
		}

		const rating: number | null = card.rating ? Number(card.rating) : null;

		return {
			card: {
				id: card_id,
				city: city_name,
				title: `${preparedTitle && title?.auto ? '* ' : ''} ${preparedTitle || ''}`,
				type: card.card_type as EActivityType,
				priceLevel: null, //card.price_level,
				priceValue: card.price,
				currency: card.currency,
				price: {
					currency: userCurrencyPrice ? currency : originalCurrency,
					value: userCurrencyPrice || card.price,
				},
				tags: [], //card.card_tags,
				images: preparedImages,
				rating: rating && rating > 0 ? Number((rating / 2).toFixed(1)) : null,
				description: `${preparedDescription && title?.auto ? '* ' : ''} ${preparedDescription || ''}`,
				hasTitleAuto: !!title?.auto,
				hasDescriptionAuto: !!texts?.auto,
				hotelRates: null,
			},
			address: {},
			info: {},
			offers: [],
			teams: {},
			supplier: {},
		};
	});
};
