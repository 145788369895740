import { ILocale } from '@main/src/types/common';

export const DEFAULT_LOCALE: ILocale = {
	code: 'ru',
	name: 'Русский',
	originalName: 'Русский',
	countryCode: 'RUS',
};

export const locales: ILocale[] = [
	{
		code: 'de',
		name: 'Немецкий',
		originalName: 'Deutsch',
		countryCode: 'DEU',
	},
	{
		code: 'en',
		name: 'Американский английский',
		originalName: 'English',
		countryCode: 'USA',
	},
	{
		code: 'es',
		name: 'Испанский',
		originalName: 'Español',
		countryCode: 'ESP',
	},
	{
		code: 'fr',
		name: 'Французкий',
		originalName: 'Français',
		countryCode: 'FRA',
	},
	{
		code: 'it',
		name: 'Итальянский',
		originalName: 'Italiano',
		countryCode: 'ITA',
	},
	DEFAULT_LOCALE,
	{
		code: 'th',
		name: 'Тайский',
		originalName: 'แบบไทย',
		countryCode: 'THA',
	},
];

export const LOCALES_CODE = locales.map(l => l.code);

export const NOT_TRANSLATION = ['de', 'es', 'fr', 'it', 'th'];
