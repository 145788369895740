import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { ISearchFilters, makeFilteredMocks } from '@app/api/services/shared/combo-search/__mock__';
import { IActivityCard } from '@main/src/types/entities/activity';

import { citySearchMapper, prepareGeoSearchResults } from './mapper';
import { captureException } from '@sentry/core';
import { ISearchResult } from '@app/api/services/shared/combo-search/type';

// TODO mocked api need sync
export const fetchSearch = async (filters: ISearchFilters): Promise<IActivityCard[]> => {
	const api = await getBrowserHttpClient();
	try {
		const result: any = await new Promise(resolve => {
			setTimeout(function () {
				resolve({
					data: {
						offers: makeFilteredMocks(filters),
					},
				});
			}, 1000);
		});

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
		return result.data.offers as IActivityCard[];
	} catch (error) {
		captureException(error);

		return [];
	}
};

// TODO movie to service/citySearch
export const fetchComboSearch = async (
	search: string,
	hasRun: boolean,
	lang: string,
	center?: [number, number]
	// visitorSession: string,
): Promise<ISearchResult> => {
	if (hasRun && search.length > 2) {
		const api = await getBrowserHttpClient();
		try {
			const result = await api.post<SerpComboSearchResp, GeoCitySearchPointReq>(
				'/client/serp/combo-search',
				{
					lang,
					search,
					center: center || [55.72454, 37.628964999999994],
					group_limit: 10,
				},
				{
					headers: {},
				}
			);

			return prepareGeoSearchResults(result, search);
		} catch (error) {
			captureException(error);

			return {
				cards: [],
				places: [],
			};
		}
	}

	return {
		cards: [],
		places: [],
	};
};
