export const sortUnixTimestamp = (firstTime: number | undefined, secondTime: number | undefined) => {
	try {
		if (firstTime && secondTime) {
			return firstTime > secondTime ? 1 : -1;
		} else {
			return -1;
		}
	} catch (e) {
		return 0;
	}
};
