import { point, points } from '@turf/helpers';
import buffer from '@turf/buffer';
import bbox from '@turf/bbox';
import { LatLng } from '@main/src/types/geo';
import center from '@turf/center';

export const bboxByCoordinates = (location: LatLng, radius = 10) => {
	const { latitude, longitude } = location;

	const p = point([longitude, latitude]);
	const buf = buffer(p, radius, { units: 'kilometers' });
	const box = bbox(buf);

	const latLng = `${latitude.toFixed(3)}/${longitude.toFixed(3)}`;

	return {
		location,
		bbox: box,
		latLng,
	};
};

export const coordinatesByBbox = (mapBbox: [[number, number], [number, number]]): number[] => {
	const features = points(mapBbox);
	const coords = center(features).geometry.coordinates;

	return [coords[1], coords[0]];
};

export const getCenter = (
	userCenter: [number, number],
	center?: [number, number],
	bbox?: [[number, number], [number, number]]
): number[] => {
	if (bbox) {
		const [lng, lat] = coordinatesByBbox(bbox);
		return [lng, lat];
	} else if (center) {
		return center;
	}

	return userCenter;
};
