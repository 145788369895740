import dayjs from 'dayjs';
import { IOrder } from '@main/src/types/orders';
import { IMapperParams } from '@app/api/services/shared/order/types';
import { EOrderStatus } from '@app/api/services/shared/orders/types';
import { DEFAULT_CURRENCY } from '@app/constants/defaults';
import { getCurrency } from '@common/helpers';
import { parseTexts, textsGetText } from '@app/api/services/node/cardAll/mapper';
import { EActivityType } from '@main/src/types/entities/activity';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const statusMapper = (status: string): EOrderStatus => {
	switch (status) {
		case 'wait_pay':
			return EOrderStatus.wait_pay;
		case 'cancel_s':
			return EOrderStatus.cancel_s;
		case 'created':
			return EOrderStatus.created;
		case 'cancel_u':
			return EOrderStatus.cancel_u;
		case 'payment':
			return EOrderStatus.payment;
		case 'processing':
			return EOrderStatus.processing;
		case 'failed':
			return EOrderStatus.failed;
		default:
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return EOrderStatus.expired;
	}
};

export const mapper = ({ response, currency, currencyRates }: IMapperParams): IOrder | undefined => {
	const originData = response?.data;
	if (originData) {
		const offerV4 = originData.offer as OrderOfferCopyV4;
		if (offerV4.t === 'v4') {
			const orderCurrency = originData.order.order_details.currency || DEFAULT_CURRENCY;
			const originalCurrency = getCurrency(orderCurrency);
			let userCurrencyPrice = null;
			const hasShowSecondPrice = currencyRates && currency && currency.code !== orderCurrency;
			if (hasShowSecondPrice) {
				userCurrencyPrice = originData.order.order_details.order_sum * currencyRates[orderCurrency][currency?.code];
			}

			let imageUrl;

			if (originData.order.order_details.offer.offer_image) {
				imageUrl = `https://img.qvedo.com/images/${originData.order.order_details.offer.offer_image}/Sx3`;
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const textsReq = parseTexts(offerV4.c.texts?.req || {});
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const textsSrc = parseTexts(offerV4.c.texts?.src || {});
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const textsAuto = parseTexts(offerV4.c.texts?.auto || {});

			const activityOffer = offerV4.c;
			return {
				type: EActivityType.activity,
				orderId: originData.order.order_id,
				code: originData.order.order_number,
				imageUrl,
				tickets: originData.order.order_details.tickets.map(ticket => {
					let userPrice = null;
					if (hasShowSecondPrice) {
						userPrice = ticket.ticket_price * currencyRates[orderCurrency][currency?.code];
					}

					return {
						id: ticket.ticket_id,
						title: ticket.ticket_title,
						price: ticket.ticket_price,
						currency: originalCurrency,
						userCurrency: currency,
						userPrice,
						quantity: ticket.ticket_number,
					};
				}),
				cardId: originData.order.order_details.card_id,
				chatId: originData.order.order_dialog,
				currency: originalCurrency,
				price: originData.order.order_details.order_sum,
				userCurrency: currency,
				userPrice: userCurrencyPrice,
				date: dayjs(originData.order.order_ts).utc().format('DD.MM.YYYY'),
				bookingDate: dayjs(originData.order.order_details.tickets_date).utc().format('DD.MM.YYYY'),
				discount: -1,
				info: {
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					dates:
						activityOffer.info.dateRange && activityOffer.info.dateRange.length
							? activityOffer.info.dateRange.join(' — ')
							: '',
					people: -1,
					cancelationRules: textsGetText('cancelationRules', textsReq, textsSrc, textsAuto) || null,
					howToGetToUs: textsGetText('howToGetToUs', textsReq, textsSrc, textsAuto) || null,
				},
				status: statusMapper(originData.order.order_status),
				title: activityOffer.card.title?.req || activityOffer.card?.title?.src || '',
				city: (activityOffer.address && activityOffer.address.display && activityOffer.address.display[1]) || undefined,
			};
		}

		const offerEtg = originData.offer as OrderOfferCopyETG;
		if (offerEtg && offerEtg.t === 'etg') {
			const orderCurrency = originData.order.order_details.currency;
			const originalCurrency = getCurrency(orderCurrency);
			// let userCurrencyPrice = null;
			// const hasShowSecondPrice = currencyRates && currency && currency.code !== orderCurrency;
			// if (hasShowSecondPrice) {
			// 	userCurrencyPrice = originData.order.order_details.order_sum * currencyRates[orderCurrency][currency?.code];
			// }

			let imageUrl;

			if (originData.order.order_details.offer.offer_image) {
				imageUrl = originData.order.order_details.offer.offer_image.replace('{size}', '1024x768');
			}

			return {
				type: EActivityType.accommodation,
				orderId: originData.order.order_id,
				code: originData.order.order_number,
				imageUrl,
				tickets: [],
				cardId: originData.order.order_details.card_id,
				chatId: originData.order.order_dialog,
				currency: originalCurrency,
				price: originData.order.order_details.order_sum,
				userCurrency: currency,
				userPrice: null,
				date: dayjs(originData.order.order_ts).utc().format('DD.MM.YYYY'),
				bookingDate: dayjs(originData.order.order_details.tickets_date).utc().format('DD.MM.YYYY'),
				discount: -1,
				info: {
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					dates: '',
					people: -1,
					// cancelationRules: textsGetText('cancelationRules', textsReq, textsSrc, textsAuto) || null,
					// howToGetToUs: textsGetText('howToGetToUs', textsReq, textsSrc, textsAuto) || null,
				},
				status: statusMapper(originData.order.order_status),
				title: originData.order.order_details.offer.offer_title || '',
				ostrovok: originData,
			};
		}
	}

	return undefined;
};
