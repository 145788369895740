import { getAvatarUrlById, getNameAndFirstName, getUrlImgById } from '@common/helpers';
import { IUserProfile } from '@main/src/types/apiCustom';
import { TOrderCard } from '@main/src/types/orders';
import { IDialogUser, TChatMessage } from '@main/src/types/profile/messages';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { EOrderStatus } from '../../orders/types';
import { IChatDialogUserMsg, TChatDialogInfo, TChatDialogMsg } from './types';

export const dialogsMapper = (
	oldDialogs: TChatDialogInfo[],
	profile: (IUserProfile | null)[],
	orders: TOrderCard[]
): IDialogUser[] => {
	return oldDialogs.map(dialog => {
		const findProfile = profile.find(item => item?.user_id === dialog.usr_two);
		const findOrder = orders.find(item => item.dialogId === dialog.dialog);

		return {
			dialog_id: dialog.dialog,
			title: dialog.dialog_subj_transl || dialog.dialog_subj || '',
			avatar: getAvatarUrlById(findProfile?.avatar?.id),
			subject: findOrder?.countTickets || 0,
			date: findOrder?.date.split(' ')?.[0],
			name: getNameAndFirstName(findProfile?.first_name, findProfile?.last_name),
			// TODO findOrder?.price for original currency and findOrder?.userPrice for rest currencies
			price: findOrder?.userPrice || findOrder?.price,
			status: findOrder?.statusOriginal || EOrderStatus.expired,
			lastMessage: '',
			hasUserCurrency: !!findOrder?.userPrice,
			newMsg: dialog.new_msg,
		};
	});
};

export const messagesMapper = (messages: TChatDialogMsg[]): TChatMessage[] => {
	return messages.map((message, index) => {
		const time = moment(new Date(message.msg_ts));
		if (message.msg_type === 'usr')
			return {
				text: DOMPurify.sanitize(message.msg_transl || message.msg_text),
				data: message?.msg_data === null ? message.msg_data : '',
				type: 'usr',
				time: time.format('l LT'),
				unix_tm: message.msg_ts,
				side: message.msg_side,
				usr_id: message.msg_from,
				firstMessageGroup: CheckFistMessageInGroup(message, index, messages),
				lastMessageGroup: CheckLastMessageInGroup(message, index, messages),
				images: message.msg_data?.images ? message.msg_data.images.map((i: string) => getUrlImgById(i) || '') : [],
				notRead: message.not_read,
			};

		return {
			code: message.msg_code || null,
			data: message?.msg_data === null ? message.msg_data : '',
			type: 'sys',
			time: time.format('L LT'),
			unix_tm: message.msg_ts,
			notRead: message.not_read,
		};
	});
};

const CheckFistMessageInGroup = (
	message: IChatDialogUserMsg,
	indexCurrentMessage: number,
	messages: TChatDialogMsg[]
): boolean => {
	const currentMessage = messages[indexCurrentMessage - 1];
	if (currentMessage) {
		if (currentMessage.msg_type !== 'sys' && message.msg_type === 'usr') {
			return currentMessage.msg_from !== message.msg_from;
		}
	}
	return true;
};

const CheckLastMessageInGroup = (
	message: IChatDialogUserMsg,
	indexCurrentMessage: number,
	messages: TChatDialogMsg[]
): boolean => {
	const currentMessage = messages[indexCurrentMessage + 1];
	if (currentMessage) {
		if (currentMessage.msg_type === 'sys') {
			return true;
		}
		if (currentMessage.msg_type === 'usr' && message.msg_type === 'usr') {
			return currentMessage.msg_from !== message.msg_from;
		}
	}
	return true;
};
