import { ICurrency } from '@main/src/types/common';

export const USD_CURRENCY: ICurrency = {
	code: 'USD',
	symbol: '$',
	prefix: true,
	name: 'Доллар США',
};

export const currencies: ICurrency[] = [
	USD_CURRENCY,
	// {
	// 	code: 'CAD',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Канадский доллар',
	// },
	{
		code: 'EUR',
		symbol: '€',
		prefix: false,
		name: 'Евро',
	},
	// {
	// 	code: 'AED',
	// 	symbol: 'د.إ.',
	// 	prefix: false,
	// 	name: 'Дирхам ОАЭ',
	// },
	// {
	// 	code: 'AFN',
	// 	symbol: '؋',
	// 	prefix: false,
	// 	name: 'Афганский афгани',
	// },
	// {
	// 	code: 'ALL',
	// 	symbol: 'Lek',
	// 	prefix: false,
	// 	name: 'Албанский Лек',
	// },
	// {
	// 	code: 'AMD',
	// 	symbol: 'դր.',
	// 	prefix: false,
	// 	name: 'Армянский драм',
	// },
	// {
	// 	code: 'ARS',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Аргентинское песо',
	// },
	// {
	// 	code: 'AUD',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Австралийский доллар',
	// },
	// {
	// 	code: 'AZN',
	// 	symbol: 'ман.',
	// 	prefix: false,
	// 	name: 'Азербайджанский манат',
	// },
	// {
	// 	code: 'BAM',
	// 	symbol: 'KM',
	// 	prefix: false,
	// 	name: 'Конвертируемая марка Боснии и Герцеговины',
	// },
	// {
	// 	code: 'BDT',
	// 	symbol: '৳',
	// 	prefix: false,
	// 	name: 'Бангладешская Така',
	// },
	// {
	// 	code: 'BGN',
	// 	symbol: 'лв.',
	// 	prefix: false,
	// 	name: 'Болгарский Лев',
	// },
	// {
	// 	code: 'BHD',
	// 	symbol: 'د.ب.',
	// 	prefix: false,
	// 	name: 'Бахрейнский динар',
	// },
	// {
	// 	code: 'BIF',
	// 	symbol: 'FBu',
	// 	prefix: false,
	// 	name: 'Бурундийский франк',
	// },
	// {
	// 	code: 'BND',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Брунейский доллар',
	// },
	// {
	// 	code: 'BOB',
	// 	symbol: 'Bs',
	// 	prefix: false,
	// 	name: 'Боливийский Боливиано',
	// },
	// {
	// 	code: 'BRL',
	// 	symbol: 'R$',
	// 	prefix: false,
	// 	name: 'Бразильский реал',
	// },
	// {
	// 	code: 'BWP',
	// 	symbol: 'P',
	// 	prefix: false,
	// 	name: 'Ботсванская Пула',
	// },
	// {
	// 	code: 'BYN',
	// 	symbol: 'руб.',
	// 	prefix: false,
	// 	name: 'Белорусский рубль',
	// },
	// {
	// 	code: 'BZD',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Белизский доллар',
	// },
	// {
	// 	code: 'CDF',
	// 	symbol: 'FrCD',
	// 	prefix: false,
	// 	name: 'Конголезский франк',
	// },
	// {
	// 	code: 'CHF',
	// 	symbol: 'CHF',
	// 	prefix: false,
	// 	name: 'Швейцарский франк',
	// },
	// {
	// 	code: 'CLP',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Чилийское песо',
	// },
	// {
	// 	code: 'CNY',
	// 	symbol: 'CN¥',
	// 	prefix: false,
	// 	name: 'Китайский юань',
	// },
	// {
	// 	code: 'COP',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Колумбийское песо',
	// },
	// {
	// 	code: 'CRC',
	// 	symbol: '₡',
	// 	prefix: false,
	// 	name: 'Костариканский Колон',
	// },
	// {
	// 	code: 'CVE',
	// 	symbol: 'CV$',
	// 	prefix: false,
	// 	name: 'Эскудо Кабо-Верде',
	// },
	// {
	// 	code: 'CZK',
	// 	symbol: 'Kč',
	// 	prefix: false,
	// 	name: 'Чешская крона',
	// },
	// {
	// 	code: 'DJF',
	// 	symbol: 'Fdj',
	// 	prefix: false,
	// 	name: 'Джибутийский франк',
	// },
	// {
	// 	code: 'DKK',
	// 	symbol: 'kr',
	// 	prefix: false,
	// 	name: 'Датская крона',
	// },
	// {
	// 	code: 'DOP',
	// 	symbol: 'RD$',
	// 	prefix: false,
	// 	name: 'Доминиканский песо',
	// },
	// {
	// 	code: 'DZD',
	// 	symbol: 'د.ج.',
	// 	prefix: false,
	// 	name: 'Алжирский динар',
	// },
	// {
	// 	code: 'EEK',
	// 	symbol: 'kr',
	// 	prefix: false,
	// 	name: 'Эстонская крона',
	// },
	// {
	// 	code: 'EGP',
	// 	symbol: 'ج.م.',
	// 	prefix: false,
	// 	name: 'Египетский фунт',
	// },
	// {
	// 	code: 'ERN',
	// 	symbol: 'Nfk',
	// 	prefix: false,
	// 	name: 'Эритрейская Накфа',
	// },
	// {
	// 	code: 'ETB',
	// 	symbol: 'Br',
	// 	prefix: false,
	// 	name: 'Эфиопский быр',
	// },
	// {
	// 	code: 'GBP',
	// 	symbol: '£',
	// 	prefix: false,
	// 	name: 'Британский фунт стерлингов',
	// },
	// {
	// 	code: 'GEL',
	// 	symbol: 'GEL',
	// 	prefix: false,
	// 	name: 'Грузинский лари',
	// },
	// {
	// 	code: 'GHS',
	// 	symbol: 'GH₵',
	// 	prefix: false,
	// 	name: 'Ганский седи',
	// },
	// {
	// 	code: 'GNF',
	// 	symbol: 'FG',
	// 	prefix: false,
	// 	name: 'Гвинейский франк',
	// },
	// {
	// 	code: 'GTQ',
	// 	symbol: 'Q',
	// 	prefix: false,
	// 	name: 'Гватемальский кетсаль',
	// },
	// {
	// 	code: 'HKD',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Гонконгский доллар',
	// },
	// {
	// 	code: 'HNL',
	// 	symbol: 'L',
	// 	prefix: false,
	// 	name: 'Гондурасская Лемпира',
	// },
	// {
	// 	code: 'HRK',
	// 	symbol: 'kn',
	// 	prefix: false,
	// 	name: 'Хорватская куна',
	// },
	// {
	// 	code: 'HUF',
	// 	symbol: 'Ft',
	// 	prefix: false,
	// 	name: 'Венгерский форинт',
	// },
	// {
	// 	code: 'IDR',
	// 	symbol: 'Rp',
	// 	prefix: false,
	// 	name: 'Индонезийская рупия',
	// },
	// {
	// 	code: 'ILS',
	// 	symbol: '₪',
	// 	prefix: false,
	// 	name: 'Новый израильский шекель',
	// },
	// {
	// 	code: 'INR',
	// 	symbol: 'টকা',
	// 	prefix: false,
	// 	name: 'Индийская рупия',
	// },
	// {
	// 	code: 'IQD',
	// 	symbol: 'د.ع.',
	// 	prefix: false,
	// 	name: 'Иракский динар',
	// },
	// {
	// 	code: 'IRR',
	// 	symbol: '﷼',
	// 	prefix: false,
	// 	name: 'Иранский риал',
	// },
	// {
	// 	code: 'ISK',
	// 	symbol: 'kr',
	// 	prefix: false,
	// 	name: 'Исландская крона',
	// },
	// {
	// 	code: 'JMD',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Ямайский доллар',
	// },
	// {
	// 	code: 'JOD',
	// 	symbol: 'د.أ.',
	// 	prefix: false,
	// 	name: 'Иорданский динар',
	// },
	// {
	// 	code: 'JPY',
	// 	symbol: '￥',
	// 	prefix: false,
	// 	name: 'Японская иена',
	// },
	// {
	// 	code: 'KES',
	// 	symbol: 'Ksh',
	// 	prefix: false,
	// 	name: 'Кенийский шиллинг',
	// },
	// {
	// 	code: 'KHR',
	// 	symbol: '៛',
	// 	prefix: false,
	// 	name: 'Камбоджийский риель',
	// },
	// {
	// 	code: 'KMF',
	// 	symbol: 'FC',
	// 	prefix: false,
	// 	name: 'Коморский франк',
	// },
	// {
	// 	code: 'KRW',
	// 	symbol: '₩',
	// 	prefix: false,
	// 	name: 'Южнокорейский вон',
	// },
	// {
	// 	code: 'KWD',
	// 	symbol: 'د.ك.',
	// 	prefix: false,
	// 	name: 'Кувейтский динар',
	// },
	// {
	// 	code: 'KZT',
	// 	symbol: 'тңг.',
	// 	prefix: false,
	// 	name: 'Казахстанский тенге',
	// },
	// {
	// 	code: 'LBP',
	// 	symbol: 'ل.ل.',
	// 	prefix: false,
	// 	name: 'Ливанский фунт',
	// },
	// {
	// 	code: 'LKR',
	// 	symbol: 'SL Re',
	// 	prefix: false,
	// 	name: 'Шри-ланкийская рупия',
	// },
	// {
	// 	code: 'LTL',
	// 	symbol: 'Lt',
	// 	prefix: false,
	// 	name: 'Литовский лит',
	// },
	// {
	// 	code: 'LVL',
	// 	symbol: 'Ls',
	// 	prefix: false,
	// 	name: 'Латвийские латы',
	// },
	// {
	// 	code: 'LYD',
	// 	symbol: 'د.ل.',
	// 	prefix: false,
	// 	name: 'Ливийский динар',
	// },
	// {
	// 	code: 'MAD',
	// 	symbol: 'د.م.',
	// 	prefix: false,
	// 	name: 'Марокканский дирхам',
	// },
	// {
	// 	code: 'MDL',
	// 	symbol: 'MDL',
	// 	prefix: false,
	// 	name: 'Молдавский лей',
	// },
	// {
	// 	code: 'MGA',
	// 	symbol: 'MGA',
	// 	prefix: false,
	// 	name: 'Малагасийский Ариари',
	// },
	// {
	// 	code: 'MKD',
	// 	symbol: 'MKD',
	// 	prefix: false,
	// 	name: 'Македонский денар',
	// },
	// {
	// 	code: 'MMK',
	// 	symbol: 'K',
	// 	prefix: false,
	// 	name: 'Мьянма Кьят',
	// },
	// {
	// 	code: 'MOP',
	// 	symbol: 'MOP$',
	// 	prefix: false,
	// 	name: 'Маканесская патака',
	// },
	// {
	// 	code: 'MUR',
	// 	symbol: 'MURs',
	// 	prefix: false,
	// 	name: 'Маврикийская рупия',
	// },
	// {
	// 	code: 'MXN',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Мексиканское песо',
	// },
	// {
	// 	code: 'MYR',
	// 	symbol: 'RM',
	// 	prefix: false,
	// 	name: 'Малайзийский ринггит',
	// },
	// {
	// 	code: 'MZN',
	// 	symbol: 'MTn',
	// 	prefix: false,
	// 	name: 'Мозамбикский метикал',
	// },
	// {
	// 	code: 'NAD',
	// 	symbol: 'N$',
	// 	prefix: false,
	// 	name: 'Доллар Намибии',
	// },
	// {
	// 	code: 'NGN',
	// 	symbol: '₦',
	// 	prefix: false,
	// 	name: 'Нигерийская Найра',
	// },
	// {
	// 	code: 'NIO',
	// 	symbol: 'C$',
	// 	prefix: false,
	// 	name: 'Никарагуанская Кордова',
	// },
	// {
	// 	code: 'NOK',
	// 	symbol: 'kr',
	// 	prefix: false,
	// 	name: 'Норвежская крона',
	// },
	// {
	// 	code: 'NPR',
	// 	symbol: 'नेरू',
	// 	prefix: false,
	// 	name: 'Непальская рупия',
	// },
	// {
	// 	code: 'NZD',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Новозеландский доллар',
	// },
	// {
	// 	code: 'OMR',
	// 	symbol: 'ر.ع.',
	// 	prefix: false,
	// 	name: 'Оманский риал',
	// },
	// {
	// 	code: 'PAB',
	// 	symbol: 'B/.',
	// 	prefix: false,
	// 	name: 'Панамский бальбоа',
	// },
	// {
	// 	code: 'PEN',
	// 	symbol: 'S/.',
	// 	prefix: false,
	// 	name: 'Перуанский Нуэво-Соль',
	// },
	// {
	// 	code: 'PHP',
	// 	symbol: '₱',
	// 	prefix: false,
	// 	name: 'Филиппинское песо',
	// },
	// {
	// 	code: 'PKR',
	// 	symbol: '₨',
	// 	prefix: false,
	// 	name: 'Пакистанская рупия',
	// },
	// {
	// 	code: 'PLN',
	// 	symbol: 'zł',
	// 	prefix: false,
	// 	name: 'Польские злотые',
	// },
	// {
	// 	code: 'PYG',
	// 	symbol: '₲',
	// 	prefix: false,
	// 	name: 'Парагвайский гуарани',
	// },
	// {
	// 	code: 'QAR',
	// 	symbol: 'ر.ق.',
	// 	prefix: false,
	// 	name: 'Катарский риал',
	// },
	// {
	// 	code: 'RON',
	// 	symbol: 'RON',
	// 	prefix: false,
	// 	name: 'Румынский лей',
	// },
	// {
	// 	code: 'RSD',
	// 	symbol: 'дин.',
	// 	prefix: false,
	// 	name: 'Сербский динар',
	// },
	{
		code: 'RUB',
		symbol: '₽',
		prefix: false,
		name: 'Российский рубль',
	},
	// {
	// 	code: 'RWF',
	// 	symbol: 'FR',
	// 	prefix: false,
	// 	name: 'Руандийский франк',
	// },
	// {
	// 	code: 'SAR',
	// 	symbol: 'ر.س.',
	// 	prefix: false,
	// 	name: 'Саудовский риал',
	// },
	// {
	// 	code: 'SDG',
	// 	symbol: 'SDG',
	// 	prefix: false,
	// 	name: 'Суданский фунт',
	// },
	// {
	// 	code: 'SEK',
	// 	symbol: 'kr',
	// 	prefix: false,
	// 	name: 'Шведская крона',
	// },
	// {
	// 	code: 'SGD',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Сингапурский доллар',
	// },
	// {
	// 	code: 'SOS',
	// 	symbol: 'Ssh',
	// 	prefix: false,
	// 	name: 'Сомалийский шиллинг',
	// },
	// {
	// 	code: 'SYP',
	// 	symbol: 'ل.س.',
	// 	prefix: false,
	// 	name: 'Сирийский фунт',
	// },
	{
		code: 'THB',
		symbol: '฿',
		prefix: false,
		name: 'Тайский бат',
	},
	// {
	// 	code: 'TND',
	// 	symbol: 'د.ت.',
	// 	prefix: false,
	// 	name: 'Тунисский динар',
	// },
	// {
	// 	code: 'TOP',
	// 	symbol: 'T$',
	// 	prefix: false,
	// 	name: 'Тонганская Паанга',
	// },
	// {
	// 	code: 'TRY',
	// 	symbol: 'TL',
	// 	prefix: false,
	// 	name: 'Турецкая лира',
	// },
	// {
	// 	code: 'TTD',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Доллар Тринидада и Тобаго',
	// },
	// {
	// 	code: 'TWD',
	// 	symbol: 'NT$',
	// 	prefix: false,
	// 	name: 'Новый тайваньский доллар',
	// },
	// {
	// 	code: 'TZS',
	// 	symbol: 'TSh',
	// 	prefix: false,
	// 	name: 'Танзанийский шиллинг',
	// },
	// {
	// 	code: 'UAH',
	// 	symbol: '₴',
	// 	prefix: false,
	// 	name: 'Украинская гривна',
	// },
	// {
	// 	code: 'UGX',
	// 	symbol: 'USh',
	// 	prefix: false,
	// 	name: 'Угандийский шиллинг',
	// },
	// {
	// 	code: 'UYU',
	// 	symbol: '$',
	// 	prefix: false,
	// 	name: 'Уругвайское песо',
	// },
	// {
	// 	code: 'UZS',
	// 	symbol: 'UZS',
	// 	prefix: false,
	// 	name: 'Узбекский сом',
	// },
	// {
	// 	code: 'VEF',
	// 	symbol: 'Bs.F.',
	// 	prefix: false,
	// 	name: 'Венесуэльский Боливар',
	// },
	// {
	// 	code: 'VND',
	// 	symbol: '₫',
	// 	prefix: false,
	// 	name: 'Вьетнамский донг',
	// },
	// {
	// 	code: 'XAF',
	// 	symbol: 'FCFA',
	// 	prefix: false,
	// 	name: 'Центральноафриканский франк КФА',
	// },
	// {
	// 	code: 'XOF',
	// 	symbol: 'CFA',
	// 	prefix: false,
	// 	name: 'Западноафриканский франк КФА',
	// },
	// {
	// 	code: 'YER',
	// 	symbol: 'ر.ي.',
	// 	prefix: false,
	// 	name: 'Йеменский риал',
	// },
	// {
	// 	code: 'ZAR',
	// 	symbol: 'R',
	// 	prefix: false,
	// 	name: 'Южноафриканский рэнд',
	// },
	// {
	// 	code: 'ZMK',
	// 	symbol: 'ZK',
	// 	prefix: false,
	// 	name: 'Замбийская квача',
	// },
	// {
	// 	code: 'ZWL',
	// 	symbol: 'ZWL$',
	// 	prefix: false,
	// 	name: 'Зимбабвийский доллар',
	// },
];
