import {
	IMapperParams,
	IOpenTime,
	TSerpCardInfoActivity,
	TSerpCardInfoGeneric,
	TSerpCardInfoOstrov,
	ICat3ClientTicket,
} from './types';
import { ICardDetail, IHotelOstrovok, IOstrovokDescription } from '@main/src/types/pages/cardDetail';
import { EDescriptionTag, ETagsTypes, IActivityDetails, IDescriptionTag, ITag } from '@main/src/types/activity';
import { get, set } from 'lodash';
import { getCurrency, getTextPrice } from '@common/helpers';
import { ITicket, ITimeSlot } from '@main/src/types/activity/detailsNext/version2';
import { TGlobMapCardType } from '@app/api/services/node/nextStartHome/types';
import { EActivityType } from '@main/src/types/entities/activity';
import { SEARCH_TAGS } from '@main/shared/filters';
import activityTaxonomy from '@main/shared/taxonomy/cat/activity.json';
import attractionTaxonomy from '@main/shared/taxonomy/cat/attraction.json';
import publicPlaceTaxonomy from '@main/shared/taxonomy/cat/public_place.json';
import restaurantTaxonomy from '@main/shared/taxonomy/cat/restaurant.json';
import { currencies } from '@app/constants/currencies';

export type TTextFromServer = Record<string, string | boolean | undefined | unknown>;
export type TTextClient = Record<string, string | string[] | boolean | boolean[] | undefined | unknown>;

export const parseTexts = (texts: TTextFromServer): TTextClient => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return Object.keys(texts).reduce((acc, prop) => {
		set(acc, prop, get(texts, prop));

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return acc;
	}, {} as any);
};

const setTextsItem = (obj: any, texts: any, item: string, prefix?: string) => {
	const fullPath = `${prefix ? prefix : ''}${item}`;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const value = get(obj, fullPath);
	if (typeof value === 'string') {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		texts[fullPath] = value;
	} else if (Array.isArray(value)) {
		value.forEach((arrayItem: any, index: number) => {
			setTextsItem(obj, texts, index.toString(), `${prefix ? prefix : ''}${item}.`);
		});
	} else {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		Object.keys(value).forEach(item => {
			setTextsItem(obj, texts, item, `${fullPath}.`);
		});
	}
};

export const convertToTexts = (obj: any) => {
	const texts: any = {};
	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	Object.keys(obj).forEach(item => {
		setTextsItem(obj, texts, item);
	});

	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return texts;
};

export const convertGlobMapCardTypeToType = (paramType: TGlobMapCardType): EActivityType => {
	switch (paramType) {
		case '1':
			return EActivityType.restaurant;
		case '2':
			return EActivityType.attraction;
		case '3':
			return EActivityType.accommodation;
		case '4':
			return EActivityType.publicPlace;
		case '5':
			return EActivityType.activity;
		default:
			return EActivityType.attraction;
	}
};

export const getTaxonomyByType = (type: EActivityType) => {
	if (type === EActivityType.restaurant) {
		return restaurantTaxonomy;
	} else if (type === EActivityType.attraction) {
		return attractionTaxonomy;
	} else if (type === EActivityType.accommodation) {
		return null;
	} else if (type === EActivityType.publicPlace) {
		return publicPlaceTaxonomy;
	} else if (type === EActivityType.activity) {
		return activityTaxonomy;
	}
};

export const getTagGroupsByType = (type: EActivityType): { [p: string]: ITag[] } | undefined => {
	if (type === EActivityType.restaurant) {
		const tagGroups: {
			ctg: ITag[];
			ct: ITag[];
			diet: ITag[];
			amenity: ITag[];
			meal: ITag[];
			family: ITag[];
			feature: ITag[];
			location: ITag[];
		} = {
			ctg: [],
			ct: [],
			diet: [],
			amenity: [],
			meal: [],
			family: [],
			feature: [],
			location: [],
		};

		return tagGroups;
	} else if (type === EActivityType.attraction) {
		const tagGroups: {
			ctg: ITag[];
			type: ITag[];
			style: ITag[];
		} = {
			ctg: [],
			type: [],
			style: [],
		};

		return tagGroups;
	} else if (type === EActivityType.accommodation) {
		return undefined;
	} else if (type === EActivityType.publicPlace) {
		const tagGroups: {
			ctg: ITag[];
		} = {
			ctg: [],
		};

		return tagGroups;
	} else if (type === EActivityType.activity) {
		const tagGroups: {
			ctg: ITag[];
			type: ITag[];
			lang: ITag[];
			group_type: ITag[];
			age_limit: ITag[];
			training: ITag[];
			transfer_type: ITag[];
			schedule_type: ITag[];
			housing_type: ITag[];
		} = {
			ctg: [],
			type: [],
			lang: [],
			group_type: [],
			age_limit: [],
			training: [],
			transfer_type: [],
			schedule_type: [],
			housing_type: [],
		};

		return tagGroups;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeTag = (type: EActivityType, group: string, tag: string): ITag => {
	return {
		name: `${type}.${tag}.name`,
		id: 'api_cat',
		type: ETagsTypes.person,
		group: `${type}.attr_type.${group}.name`,
	};
};

// for old tags
export const prepareMoreTags = (
	typeByBackend: TGlobMapCardType,
	tags?: string[]
): { [p: string]: ITag[] } | undefined => {
	const type = convertGlobMapCardTypeToType(typeByBackend);
	const taxonomy = getTaxonomyByType(type);
	const tagGroups = getTagGroupsByType(type);

	if (tagGroups && tags && tags.length > 0) {
		if (type === EActivityType.activity) {
			tags.forEach(tag => {
				if (!get(taxonomy, tag)) {
					return;
				}

				const [group, , nestedGroup] = tag.split('.');

				if (group === 'ctg' && !nestedGroup) {
					tagGroups.ctg.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'ctg' && nestedGroup === 'type') {
					tagGroups.type.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'lang') {
					tagGroups.lang.push(makeTag(type, group, tag));
				}

				if (group === 'group_type') {
					tagGroups.group_type.push(makeTag(type, group, tag));
				}

				if (group === 'age_limit') {
					tagGroups.age_limit.push(makeTag(type, group, tag));
				}

				if (group === 'training') {
					tagGroups.training.push(makeTag(type, group, tag));
				}

				if (group === 'transfer_type') {
					tagGroups.transfer_type.push(makeTag(type, group, tag));
				}

				if (group === 'schedule_type') {
					tagGroups.schedule_type.push(makeTag(type, group, tag));
				}

				if (group === 'housing_type') {
					tagGroups.housing_type.push(makeTag(type, group, tag));
				}
			});
		} else if (type === EActivityType.attraction) {
			tags.forEach(tag => {
				if (!get(taxonomy, tag)) {
					return;
				}

				const [group, , nestedGroup] = tag.split('.');

				if (group === 'ctg' && !nestedGroup) {
					tagGroups.ctg.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'ctg' && nestedGroup === 'type') {
					tagGroups.type.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'style') {
					tagGroups.style.push(makeTag(type, group, tag));
					return;
				}
			});
		} else if (type === EActivityType.publicPlace) {
			tags.forEach(tag => {
				if (!get(taxonomy, tag)) {
					return;
				}

				const [group, , nestedGroup] = tag.split('.');

				if (group === 'ctg' && !nestedGroup) {
					tagGroups.ctg.push(makeTag(type, group, tag));
					return;
				}
			});
		} else if (type === EActivityType.restaurant) {
			tags.forEach(tag => {
				if (!get(taxonomy, tag)) {
					return;
				}

				const [group, , nestedGroup] = tag.split('.');

				if (group === 'ctg' && !nestedGroup) {
					tagGroups.ctg.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'ct') {
					tagGroups.ct.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'meal') {
					tagGroups.meal.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'diet') {
					tagGroups.diet.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'location') {
					tagGroups.location.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'family') {
					tagGroups.family.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'amenity') {
					tagGroups.amenity.push(makeTag(type, group, tag));
					return;
				}

				if (group === 'feature') {
					tagGroups.feature.push(makeTag(type, group, tag));
					return;
				}
			});
		}
	}

	return tagGroups;
};

export const prepareTags = (typeByBackend: TGlobMapCardType, tags: string[]): ITag[] => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const filters = SEARCH_TAGS[typeByBackend];
	const preparedTags = tags
		.map(tag => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const currentTag = filters[tag];
			if (currentTag) {
				return {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
					name: currentTag.t,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
					id: currentTag.ns,
					type: ETagsTypes.person,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
					group: currentTag.key,
				};
			}
		})
		.filter(f => f !== undefined);

	return preparedTags as ITag[];
};
interface IPropsAdditionTags {
	duration?: number;
	scheduleType?: string;
	creator?: string;
	attractionStyles?: string[];
	firstPerson?: string;
}
export const prepareAdditionalTags = (props: IPropsAdditionTags): IDescriptionTag[] => {
	const tags: IDescriptionTag[] = [];

	if (props.scheduleType) {
		tags.push({
			type: EDescriptionTag.scheduleType,
			value: props.scheduleType,
		});
	}
	if (props.duration) {
		tags.push({
			type: EDescriptionTag.duration,
			value: `${props.duration}`,
		});
	}
	if (props.creator) {
		tags.push({
			type: EDescriptionTag.creator,
			value: props.creator,
		});
	}
	if (props.firstPerson) {
		tags.push({
			type: EDescriptionTag.firstPerson,
			value: props.firstPerson,
		});
	}
	if (props.attractionStyles) {
		tags.push({
			type: EDescriptionTag.attractionStyles,
			value: props.attractionStyles,
		});
	}
	return tags;
};
export const textsGetText = (
	key: string,
	req: TTextClient,
	src: TTextClient,
	auto: TTextClient
): string | undefined => {
	const textReq = req[key];
	const textSrc = src[key];
	const textAuto = auto[key];

	if (!!textReq && typeof textReq === 'string') {
		if (!!textAuto) {
			return `* ${textReq}`;
		} else {
			return textReq;
		}
	} else if (!!textSrc && typeof textSrc === 'string') {
		return textSrc;
	}

	return undefined;
};

export function textsGetArray<T>(key: string, req: TTextClient, src: TTextClient): T[] | undefined {
	const textReq = req[key];
	const textSrc = src[key];

	if (Array.isArray(textReq)) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return textReq;
	} else if (Array.isArray(textSrc)) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return textSrc;
	}

	return undefined;
}

const filterPolicies = (policies: Record<string, any>) => {
	return Object.entries(policies).reduce<{
		free: Record<string, any>;
		paid: Record<string, any>;
		hasFree: boolean;
		hasPaid: boolean;
	}>(
		(acc, [policy, value]) => {
			if (['visa'].includes(policy)) {
				return acc;
			}

			if (['add_fee', 'no_show', 'deposit', 'children'].includes(policy)) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				acc.paid[policy] = value;
				return acc;
			}

			if (!Array.isArray(value)) {
				return acc;
			}

			acc.free[policy] = [];
			acc.paid[policy] = [];

			value.forEach(val => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				if (val.inclusion && val.inclusion === 'included') {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
					acc.free[policy].push(val);
					acc.hasFree = true;
				} else {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
					acc.paid[policy].push(val);
					acc.hasPaid = true;
				}
			});

			return acc;
		},
		{
			free: {},
			paid: {},
			hasFree: false,
			hasPaid: false,
		}
	);
};

export const mapperCardAll = ({ result, currency, currencyRates, locale }: IMapperParams): ICardDetail | null => {
	const { data } = result;
	// const { data } = sampleHotel;

	if (data) {
		const type = convertGlobMapCardTypeToType(data.ct);
		const tags = prepareTags(data.ct, data.card.tags);
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
		const moreTags = prepareMoreTags(data.ct, data.info.more_tags);

		const descriptionTags = prepareAdditionalTags({
			duration: data.info_type === 'a1' ? data.info.duration : undefined,
			scheduleType: tags.find(i => i.name.indexOf('schedule_type') > 0)?.name,
			creator: data.info_type === 'b0' ? data.texts?.req?.creator : undefined,
			attractionStyles: moreTags?.['style'] ? moreTags.style.map(i => i.name) : undefined,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			firstPerson: data.info_type === 'b0' ? data.info.more?.firstPerson : undefined,
		});

		let images = undefined;
		let textsReq = null;
		let textsSrc = null;
		let textsAuto = null;
		let days = null;
		let contacts = null;
		let nameLocation = null;
		let details: IActivityDetails | null = null;
		let parameters = null;
		let newWorkTime = null;
		let tickets: ITicket[] = [];
		let ostrovokDescription: IOstrovokDescription[] | null = null;
		let ostrovok: IHotelOstrovok | null = null;
		let activitySlots: ITimeSlot[] = [];

		if (data.info_type === 'o1') {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			const currentData = data as TSerpCardInfoOstrov;

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let oPolicies: { [key: string]: any } | null = null;
			let oAmenityGroups: { amenities: string[]; group_name: string }[] | null = null;
			let oMetapolicyExtraInfo: string | null = null;
			let oMetapolicyStruct: unknown | null = null;
			let priceFormattedStr: string | null = null;

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
			oAmenityGroups = currentData.info.amenity_groups;
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
			oMetapolicyExtraInfo = currentData.info.metapolicy_extra_info;
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
			if (currentData.info?.metapolicy_struct) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
				oMetapolicyStruct = data.info.metapolicy_struct;
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
				oPolicies = filterPolicies(data.info.metapolicy_struct as Record<string, any>);
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
			const [checkInTimeHour, checkInTimeMinutes, _in] = currentData.info.check_in_time.split(':') as string[];
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
			const [checkOutTimeHour, checkOutTimeMinutes, _out] = currentData.info.check_out_time.split(':') as string[];

			ostrovok = {
				policies: oPolicies,
				amenityGroups: oAmenityGroups,
				metapolicyExtraInfo: oMetapolicyExtraInfo,
				metapolicyStruct: oMetapolicyStruct,
				checkInTime: `${checkInTimeHour}:${checkInTimeMinutes}`,
				checkOutTime: `${checkOutTimeHour}:${checkOutTimeMinutes}`,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
				starRating: currentData.info.star_rating || null,
			};

			images = [
				...currentData.card.images,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
				...currentData.info.images,
			]
				.slice(0, 12)
				.map(i => ({
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
					thumbnail: i.replace('{size}', '1024x768'),
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
					original: i.replace('{size}', '1024x768'),
				}));

			textsReq = parseTexts(data.card.texts?.req || {});
			textsSrc = parseTexts(data.card.texts?.src || {});
			textsAuto = parseTexts(data.card.texts?.auto || {});

			if (currentData.card?.price) {
				const currentCurrency =
					currency && !!currentData.card.price[currency.code]
						? currency
						: currencies.find(({ code }) => {
								return currentData.card.price && code in currentData.card.price;
						  });

				if (currency && currencyRates && currentCurrency) {
					const getPriceValue = (code: string, hasPrice?: boolean) => {
						let currentPrice: number;
						let checkedCurrency = currency;
						if (currentData.card.price) {
							if (hasPrice) {
								currentPrice = currentData.card.price[code];
								checkedCurrency = getCurrency(code);
							} else {
								currentPrice = currentData.card.price[currentCurrency.code] * currencyRates[currentCurrency.code][code];
							}
							return getTextPrice(EActivityType.accommodation, null, {
								value: Math.ceil(currentPrice / 100),
								currency: checkedCurrency,
							});
						}
					};

					let priceFirstValue;
					let priceSecondValue;
					if (locale !== 'ru') {
						priceFirstValue = getPriceValue('USD', !!currentData.card.price['USD']);
						priceSecondValue = currency?.code !== 'USD' ? getPriceValue(currency?.code) : '';
					} else {
						priceFirstValue = getPriceValue('RUB', !!currentData.card.price['RUB']);
						priceSecondValue = currency?.code !== 'RUB' ? getPriceValue(currency?.code) : '';
					}

					const currencyPrice = priceSecondValue ? ` <span>(~${priceSecondValue})</span>` : '';
					priceFormattedStr = `${priceFirstValue ?? ''}${currencyPrice}`;
				}
			}

			const address = currentData.address?.display?.join(', ') || '';
			nameLocation = {
				locationName: address,
				address,
				city: currentData.address?.city_name || '',
				rate: (currentData.card?.rating && currentData.card?.rating / 100) || 0,
				reviewCount: -1,
			};
			details = {
				text:
					textsGetText('description', textsReq, textsSrc, textsAuto) ||
					textsGetText('short', textsReq, textsSrc, textsAuto) ||
					'',
				price: 0,
				priceFormattedStr,
				// TODO такого не будет, над будет доставать из тегов
				// priceLevel: currentData.card.price_level,
			};

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
			ostrovokDescription = data.info.description_struct;
		} else if (data.info_type === 'b0') {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			const currentData = data as TSerpCardInfoGeneric;

			images = [...currentData.images, ...currentData.card.images].map(i => ({
				thumbnail: `https://img.qvedo.com/images/${i}/Sx3`,
				original: `https://img.qvedo.com/images/${i}/Vx2`,
			}));
			textsReq = parseTexts(data.texts?.req || {});
			textsSrc = parseTexts(data.texts?.src || {});
			textsAuto = parseTexts(data.texts?.auto || {});
			contacts = {
				web: data.info.contacts.web || [],
				email: currentData.info.contacts.email || [],
				phone: currentData.info.contacts.phone || [],
				fax: currentData.info.contacts.fax || [],
				social: [],
				socialStrings: currentData.info.contacts.social || [],
				// TODO more? object
				// more: currentData.info.more?.social_links?.map(i => i.url) || [],
			};
			const withoutPostalIndex = currentData.address?.display.slice(0, currentData.address?.display.length - 2) || [];
			const address = withoutPostalIndex?.join(', ') || '';
			nameLocation = {
				locationName: address,
				address,
				city: currentData.address?.city_name || '',
				rate: (currentData.card?.rating && currentData.card?.rating / 100) || 0,
				reviewCount: -1,
			};

			newWorkTime = currentData.info.open_time ? convertDeepNumbersToTime(currentData.info.open_time) : [];
			const priceLevel = tags.find(f => f.name.startsWith('price_level.'));
			details = {
				text:
					textsGetText('description', textsReq, textsSrc, textsAuto) ||
					textsGetText('short', textsReq, textsSrc, textsAuto) ||
					'',
				// TODO need added calc price
				price: 0, //currentData.card.price,
				// TODO такого не будет, над будет доставать из тегов
				priceLevel: priceLevel?.name || null,
			};
		} else if (data.info_type === 'a1') {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			const currentData = data as TSerpCardInfoActivity;

			images = [...currentData.images, ...currentData.card.images].map(i => ({
				thumbnail: `https://img.qvedo.com/images/${i}/Sx3`,
				original: `https://img.qvedo.com/images/${i}/Vx2`,
			}));
			textsReq = parseTexts(data.texts?.req || {});
			textsSrc = parseTexts(data.texts?.src || {});
			textsAuto = parseTexts(data.texts?.auto || {});
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const daysById = (textsReq?.daysById || {}) as any;
			days = (currentData.info.days || []).map(day => ({
				images_ids: day.images_ids || [],
				housing_type: day.housing_type && `api_cat:activity.housing_type.${day.housing_type}.name`,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
				title: daysById[day.id]?.title || '',
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
				description: daysById[day.id]?.description || '',
			}));

			const withoutPostalIndex = currentData.address?.display.slice(0, currentData.address?.display.length - 2) || [];
			const address = withoutPostalIndex?.join(', ') || '';
			nameLocation = {
				locationName: address,
				address,
				city: currentData.address?.city_name || '',
				rate: (currentData.card?.rating && currentData.card?.rating / 100) || 0,
				reviewCount: -1,
			};

			const allTickets = currentData.tickets || [];
			const mainTickets = allTickets.filter(ticket => {
				return ticket.data.c.ticket_type === 'main';
			});

			const ticketsSort = (mainTickets.length ? mainTickets : allTickets).sort((a, b) => {
				return a.data.c.price - b.data.c.price;
			});

			const displayTicket = ticketsSort.length ? ticketsSort[0] : undefined;
			const displayPriceType = displayTicket?.data.c.price_type;

			details = {
				text:
					textsGetText('description', textsReq, textsSrc, textsAuto) ||
					textsGetText('short', textsReq, textsSrc, textsAuto) ||
					'',
				startPointDescription: textsGetText('startPointDescription', textsReq, textsSrc, textsAuto) || null,
				finishPointDescription: textsGetText('finishPointDescription', textsReq, textsSrc, textsAuto) || null,
				cancelationRules: textsGetText('cancelationRules', textsReq, textsSrc, textsAuto) || null,
				howToGetToUs: textsGetText('howToGetToUs', textsReq, textsSrc, textsAuto) || null,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				duration: currentData.info.duration || null,
				hasTextAuto: !!textsAuto.description || !!textsAuto.short || false,
				hasStartPointDescriptionAuto: !!textsAuto.startPointDescription || false,
				hasFinishPointDescriptionAuto: !!textsAuto.finishPointDescription || false,
				hasCancelationRulesAuto: !!textsAuto.cancelationRules || false,
				hasWowToGetToUsAuto: !!textsAuto.howToGetToUs || false,
				activityRestrictions: textsGetArray<string>('activityRestrictions', textsReq, textsSrc) || [],
				hasActivityRestrictions: (textsAuto['activityRestrictions'] as boolean[]) || [],
				participationRequirement: textsGetArray<string>('participationRequirement', textsReq, textsSrc) || [],
				hasParticipationRequirement: (textsAuto['participationRequirement'] as boolean[]) || [],
				recomendedToTake: textsGetArray<string>('recomendedToTake', textsReq, textsSrc) || [],
				hasRecomendedToTake: (textsAuto['recomendedToTake'] as boolean[]) || [],
				// TODO need added calc price
				price: 0, //currentData.card.price,
				// TODO такого не будет, над будет доставать из тегов
				// priceLevel: currentData.card.price_level,
				priceFormattedStr: displayPriceType,
			};
			parameters = {
				departure: '',
				departureTime: '',
				destination: '',
				excluded: textsGetArray<string>('whatsExcluded', textsReq, textsSrc) || [],
				included: textsGetArray<string>('whatsIncluded', textsReq, textsSrc) || [],
				returnTime: '',
			};
			newWorkTime = currentData.info.open_time ? convertDeepNumbersToTime(currentData.info.open_time) : [];

			const ticketsType: {
				main: ICat3ClientTicket[];
				kids: ICat3ClientTicket[];
				extra: ICat3ClientTicket[];
				empty: ICat3ClientTicket[];
			} = {
				main: [],
				kids: [],
				extra: [],
				empty: [],
			};

			const ticketsPriceSort = currentData.tickets.sort((a, b) => {
				const priceA = a.data?.c.price ?? 0;
				const priceB = b.data?.c.price ?? 0;
				return priceA - priceB;
			});

			ticketsPriceSort.forEach(item => {
				const ticketType = item.data?.c.ticket_type || 'empty';
				ticketsType[ticketType].push(item);
			});

			const ticketsScheme = [...ticketsType.main, ...ticketsType.kids, ...ticketsType.extra, ...ticketsType.empty];

			tickets = ticketsScheme.map(ticket => {
				const originalCurrency = (ticket.data?.c?.currency && getCurrency(ticket.data?.c?.currency)) || null;
				let userCurrencyPrice = null;
				if (
					currencyRates &&
					currency &&
					originalCurrency &&
					ticket.data?.c?.price &&
					currency.code !== originalCurrency?.code
				) {
					userCurrencyPrice = ticket.data?.c?.price * currencyRates[originalCurrency?.code][currency.code];
				}

				return {
					lang: 'ru', //data.i18n.lang,
					ticketId: ticket.ticket_id,
					title: ticket.texts?.req.title || ticket.texts?.src.title || '',
					description: ticket.texts?.req.description || ticket.texts?.src.description || '',
					currency: originalCurrency,
					price: ticket.data?.c?.price || -1,
					userCurrency: currency,
					userPrice: userCurrencyPrice,
					imageId: ticket.data?.c?.image_id ? `https://img.qvedo.com/images/${ticket.data?.c?.image_id}/Mx2` : null,
					displayPriceType: ticket?.data?.c?.price_type,
					type: ticket.data.c.ticket_type,
				};
			});
			activitySlots = currentData.slots.map(i => {
				return {
					timeSlot: i.time_slot,
					hidden: i.hidden,
				};
			});
		}

		const title = data.card?.title?.req || data.card?.title?.src || '';

		return {
			entry: title,
			hasTitleAuto: data.card.title?.auto,
			tags,

			descriptionTags,
			moreTags: moreTags || null,
			center: [data.geo?.lat6 / 1000000, data.geo.lon6 / 1000000],
			contacts,
			nameLocation: nameLocation && {
				...nameLocation,
				name: title,
			},
			imagesGallery: images,
			details,
			offers: [],
			parameters,
			// TODO current supplier type = unknown
			// provider: {
			// 	description: data.data.supplier?.description || '',
			rating: data.card.rating || null,
			// 	name: data.data.supplier?.displayName || '',
			// 	contacts: [],
			// 	avatarUrl: (data.data.supplier?.cover && `https://img.qvedo.com/avatars/${data.data.supplier.cover}/Sx3`) || '',
			// },
			provider: {
				id: data.supplier?.id,
				name: data.supplier?.displayName,
				description: data.supplier?.description,
				avatarUrl: data.supplier?.cover && `https://img.qvedo.com/avatars/${data.supplier.cover}/Sx3`,
				rating: data.card.rating,
				contacts: [],
			},
			type,
			originalType: data.ct,
			reviews: [],
			supplierTeam: [],
			work_time: null,
			// TODO move to work_time
			newWorkTime,
			tickets,
			days,
			ostrovokDescription,
			ostrovok,
			activityTimeSlots: activitySlots,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			roomGroups: data.info.room_groups,
			de: data.de,
		};
	}

	return null;
};

export function numberToTime(item: number): string {
	const lengthItem = String(item).length;
	const nullsString = new Array(5 - lengthItem).join('0');

	const array = `${nullsString}${item}`.split('');

	return `${array[0]}${array[1]}:${array[2]}${array[3]}`;
}

const convertDeepNumbersToTime = (time: IOpenTime): Array<Array<[string, string] | []>> | null => {
	if (time.type === 'all_time') return null;

	if (time?.week_time) {
		return time?.week_time.map(items => {
			return items.map(item => {
				if (item.length === 1) {
					const beginTime = item[0];

					return [numberToTime(beginTime), numberToTime(2359)];
				} else if (item.length === 2) {
					const beginTime = item[0];
					const endTime = item[1];

					return [numberToTime(beginTime), numberToTime(endTime)];
				}

				return [];
			});
		});
	}

	return null;
};
