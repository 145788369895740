import { ITag } from '@main/src/types/activity';

const CARD_ACCOMMODATION_ST: { [key: string]: number } = {
	'st.one_star': 1,
	'st.two_star': 2,
	'st.three_star': 3,
	'st.four_star': 4,
	'st.five_star': 5,
};
export const getStarRating = (tags: ITag[] | undefined): number | null => {
	if (tags) {
		const result = tags.find(({ group }) => group?.includes('st.'));

		if (result?.group) {
			return CARD_ACCOMMODATION_ST[result?.group];
		}
		return null;
	}
	return null;
};
