import { ICardResultV2, IPoint, ISearch, ITicketsCustom } from '@main/src/types/map/searchArea';
import { EActivityType } from '@main/src/types/entities/activity';
import { DEFAULT_CURRENCY } from '@app/constants/defaults';
import { TCardType, TGlobMapCardType } from './types';
import { TAreaSimpleResp } from './areaTitle/types';
import { ICurrencyRates } from '@main/src/types/common/currencyRates';
import { ICurrency } from '@main/src/types/common';
import { getCurrency } from '@common/helpers';
import { ETagsTypes, ITag } from '@main/src/types/activity';
import { SEARCH_TAGS } from '@main/shared/filters';
import { currencies } from '@app/constants/currencies';

export const convertGlobMapCardTypeToType = (paramType: TGlobMapCardType): string => {
	switch (paramType) {
		case '1':
			return 'restaurant';
		case '2':
			return 'attraction';
		case '3':
			return 'accommodation';
		case '4':
			return 'public_place';
		case '5':
			return 'activity';
		default:
			return 'any';
	}
};

export const convertTypeToGlobMapCardType = (paramType: string): TGlobMapCardType | undefined => {
	switch (paramType) {
		case 'restaurant':
			return '1';
		case 'attraction':
			return '2';
		case 'accommodation':
			return '3';
		case 'public_place':
			return '4';
		case 'publicPlace':
			return '4';
		case 'activity':
			return '5';

		default:
			return undefined;
	}
};

export const prepareTags = (typeByBackend: TGlobMapCardType, tags: string[]): ITag[] => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const filters = SEARCH_TAGS[typeByBackend];
	const preparedTags = tags
		.map(tag => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const currentTag = filters[tag];
			if (currentTag) {
				return {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
					name: currentTag.t,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
					id: currentTag.ns,
					type: ETagsTypes.person,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
					group: currentTag.key,
				};
			}
		})
		.filter(f => f !== undefined);

	return preparedTags as ITag[];
};

export const IMAGE_SIZE = '640x400';

export const offersMapper = (
	data: TAreaSimpleResp,
	typeNumber: string | undefined,
	currencyRates?: ICurrencyRates | null,
	currency?: ICurrency | null,
	locale?: string
): ISearch => {
	const objectTags = Object.values(data.map_info.stat).reduce((acc, item) => {
		return {
			...acc,
			...item.tags,
		};
	}, {} as Record<string, number>);
	const tags = Object.keys(objectTags);

	const tagGroups = Array.from(
		new Set(
			tags.map(item => {
				const [first, ...rest] = item.split('.');

				return first;
			}, {})
		)
	).sort(function (a, b) {
		if (a > b) {
			return 1;
		}
		if (a < b) {
			return -1;
		}

		return 0;
	});

	const prepared: GeoJSON.Feature<GeoJSON.Point, IPoint>[] = data.map_data.map(
		(item, index): GeoJSON.Feature<GeoJSON.Point, IPoint> => {
			const { stat, cnt, view, ll6 } = item;
			const [ll6Lat, ll6lng] = ll6.split(',');
			const lat = parseInt(ll6Lat, 10) / 1000000;
			const lng = parseInt(ll6lng, 10) / 1000000;

			const type = convertGlobMapCardTypeToType(Object.keys(stat)[0] as TGlobMapCardType) as TCardType;

			const lngLat: [number, number] = [Number(lng), Number(lat)];

			const isMulti = view > 1;

			return {
				type: 'Feature',
				properties: {
					tile: index.toString(),
					id: ll6,
					cluster: '',
					center: lngLat,
					cards: [''],
					type: isMulti ? 'cluster' : type,
					multi: isMulti,
					index,
					coordinates: lngLat,
				},
				id: ll6,
				geometry: {
					type: 'Point',
					coordinates: lngLat,
				},
			};
		}
	);

	const cards = data.cards.map(i => i.card.card_id);
	const nextCards = data.cards.map((card): ICardResultV2 => {
		const { card_id, card_point, price, tags, rating } = card.card;
		const type = convertGlobMapCardTypeToType(card.ct) as EActivityType;
		// const title =
		// 	(card.card.title?.auto && card.card.title?.req && `* ${card.card.title.req}`) ||
		// 	card.card.title?.src ||
		// 	'нет названия';
		const title = card.card.title?.req || card.card.title?.src || 'нет названия';
		const images = card.card.images.map(image => {
			if (image.startsWith('https')) {
				return image.replace('{size}', IMAGE_SIZE);
			}

			return `https://img.qvedo.com/images/${image}/Mx2`;
		});
		const preparedCardTags = prepareTags(card.ct, card.card.tags);
		const description = card.card.texts?.req.short || card.card.texts?.src.short || '';
		const city = card.geo.city_name && card.geo.city_name.length > 0 ? card.geo.city_name[0] : '';

		let priceFirstValue;
		let priceFirstCurrency;
		let priceSecondValue;
		const priceCurrencies = Object.keys(price || {});
		if (priceCurrencies.length && currency?.code && price) {
			const currentCurrency =
				currency && !!price[currency.code]
					? currency
					: currencies.find(({ code }) => {
							return price && code in price;
					  });

			if (currency && currencyRates && currentCurrency) {
				const getPriceValue = (code: string, hasPrice?: boolean) => {
					let currentPrice: number;
					if (hasPrice) {
						currentPrice = price[code];
					} else {
						currentPrice = price[currentCurrency.code] * currencyRates[currentCurrency.code][code];
					}
					return Math.ceil(currentPrice / 100);
				};

				if (type === EActivityType.accommodation) {
					if (locale !== 'ru') {
						priceFirstValue = getPriceValue('USD', !!price['USD']);
						priceFirstCurrency = getCurrency('USD');
						priceSecondValue = currency?.code !== 'USD' ? getPriceValue(currency?.code) : null;
					} else {
						priceFirstValue = getPriceValue('RUB', !!price['RUB']);
						priceFirstCurrency = getCurrency('RUB');
						priceSecondValue = currency?.code !== 'RUB' ? getPriceValue(currency?.code) : null;
					}
				} else {
					const [key] = priceCurrencies;
					priceFirstValue = getPriceValue(key);
					priceFirstCurrency = getCurrency(key);
					priceSecondValue = currency?.code !== key ? getPriceValue(currency?.code) : null;
				}
			}
		}

		let priceLevel = null;
		if (type === 'restaurant') {
			const preparedPriceLevel = preparedCardTags.find(f => f.name.startsWith('price_level.'));
			priceLevel = preparedPriceLevel?.name || null;
		}

		// TODO refactor
		const allTickets: Cat3ClientTicket[] = card.tickets || [];
		const mainTickets = allTickets.filter(ticket => {
			return ticket.data.c.ticket_type === 'main';
		});

		const ticketsSort = (mainTickets.length ? mainTickets : allTickets).sort((a, b) => {
			return a.data.c.price - b.data.c.price;
		});

		const displayTicket = ticketsSort.length ? ticketsSort[0] : undefined;
		const displayPriceType = displayTicket?.data.c.price_type;

		let originalCurrency = null;
		let originalCurrencyPrice = null;
		let userCurrencyPrice = null;

		if (currencyRates) {
			if (displayTicket?.data?.c?.currency) {
				originalCurrency = getCurrency(displayTicket?.data.c.currency);

				if (originalCurrency?.code !== displayTicket?.data.c.currency) {
					originalCurrencyPrice =
						displayTicket?.data?.c?.price * currencyRates[displayTicket?.data.c.currency][originalCurrency.code];
				}
			}
			if (
				currencyRates &&
				currency &&
				originalCurrency &&
				displayTicket?.data?.c?.price &&
				currency.code !== originalCurrency?.code
			) {
				const currentPrice = originalCurrencyPrice || displayTicket?.data.c.price;
				userCurrencyPrice = Math.ceil(currentPrice * currencyRates[originalCurrency?.code][currency.code]);
			}
		}

		const ticketsData: ITicketsCustom = {
			count: allTickets.length,
			priceTicket: originalCurrencyPrice || displayTicket?.data?.c?.price || 0,
			displayPriceType,
			currency: originalCurrency,
			userCurrency: currency,
			userPrice: userCurrencyPrice,
			tickets: allTickets,
		};

		return {
			card: {
				id: card_id,
				title: title,
				price: {
					value: priceFirstValue,
					currency: priceFirstCurrency,
					userPrice: priceSecondValue,
					userCurrency: currency,
				},
				type,
				// image: images[0],
				priceLevel,
				rating: rating || null,
				// openNow: true,
				// closed: null,
				description,
				city,
				images,
				tags: preparedCardTags,
				// distance: -1,
				priceValue: 111,
				currency: DEFAULT_CURRENCY,
				hasTitleAuto: card.card.title?.auto || false,
				hasDescriptionAuto: false,
				hotelRates: null,
				tickets: ticketsData,
			},
			offers: [],
			address: {},
			info: {},
			supplier: {},
			teams: {},
		};
	});

	return {
		tagGroups,
		tags: objectTags,
		pointsByIndex: prepared,
		cards,
		nextCards,
		found: data.map_info.cnt,
		foundListing: data.map_info.view,
		requestId: data.map_req,
	};
};
