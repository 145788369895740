import type { FC } from 'react';
import type { InputProps } from 'antd/lib/input';

import AntInput from 'antd/lib/input';

// export { InputProps };

export const Input: FC<InputProps> = props => {
	return <AntInput {...props} />;
};
