type KeysProp<Option> = {
	label?: keyof Option;
	value?: keyof Option;
};

export const generateSelect = <Option extends Record<string, any>>(
	options?: Option[],
	keys?: KeysProp<Option>
): (Option & { label: string; value: string })[] | [] => {
	if (!options || !Array.isArray(options)) {
		return [];
	}

	const keyLabel = keys?.label || 'title';
	const keyValue = keys?.value || 'id';

	const result = options.map(i => ({ ...i, label: i[keyLabel], value: i[keyValue] }));

	return result;
};
