import type { ListProps } from 'antd/lib/list';

import AntList from 'antd/lib/list';

export function List<T>(props: ListProps<T>) {
	return <AntList {...props} />;
}

List.Item = AntList.Item;
List.Item.Meta = AntList.Item.Meta;
