// import api, { IndexLocalesResponse, LocaleResponse } from '@app/api';
import axios from 'axios';
// import { localesMapper } from '@app/api/services/node/locales/mapper';
import { ILocale } from 'src/types/common';
import { locales } from '@app/constants/locales';
import { captureException } from '@sentry/core';

export const fetchLocales = async (): Promise<ILocale[]> => {
	try {
		// const result = await nodeHttpApi.apiget<{ data: IndexLocalesResponse }>('/locales');

		return new Promise(resolve => resolve(locales));

		// return localesMapper(result.data.locales);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// eslint-disable-next-line no-console
			console.log('axios error fetchLocales', error.message); // TODO use sentry
		} else {
			// eslint-disable-next-line no-console
			console.log('unexpected error fetchLocales', error); // TODO use sentry
		}

		return [];
	}
};
